import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "signup": "Sign Up",
          "signin": "Sign In",
          "signin-already-have-account": "Already have an account?",
          "signin-with-google": "Sign In with Google",
          "signin-no-account": "No Account?",
          "signin-forgot-password": "Forgot Password?",
          "update-profile": "Update Profile",
          "become-tourguide": "Become a Tour Guide!",
          "search-go": "Go!",
          "search-random": "Random",
          "search-placeholder": "Search Destinations",

          "home-hero-title": "Go local on your next adventure",
          "home-hero-subtitle": "Torgyd is a global platform for independent tour guides to give you an unforgettable local experience",
          "home-signup-ad": "Sign up to be a tour guide, offer incredible adventures, meet people from around the world and earn money.",

          "ewtn-title": "Eat with the Natives",
          "ewtn-subtitle": "On your next trip, make a local connection.",
          "ewtn-rules": "The Rules",
          "ewtn-whatnot": "This is not a dating app. This is a fun way to meet someone from another place. It's for solo travelers, couples, families, and groups.",
          "ewtn-visitor-rules": "Visitor Rules",
          "ewtn-visitor-rules-subtitle": "You are a visitor. Be cool. Leave that ethnocentric mentality at home.",
          "ewtn-visitor-rule-1": "This meal is on you.  You are expected to buy the local their meal. You are only required to pay for one local!",
          "ewtn-visitor-rule-2": "You tell the local how much you are willing to pay and see if they can accomodate.",
          "ewtn-visitor-rule-3": "Let the local know how many are in your party.  Maybe you're a solo traveler or a group of 4 looking to know someone in the city.",
          "ewtn-visitor-rule-4": "You tell the local what language you speak.  Perhaps you are English but want to speak in Spanish during the meal to practice.  Let them know.",
          "ewtn-visitor-rule-5": "You arrange your own transportation.",
          "ewtn-visitor-rule-6": "You Show up on time! None of this \"People-in-my-culture\" excuses.",
          "ewtn-visitor-rule-7": "Be cool. Be kind.",
          "ewtn-visitor-get-started": "Get Started as a Visitor",
          "ewtn-visitor-howto": "Search for a city and see who's available. Look at their social media profiles and contact them from those channels.",
          "ewtn-local-rules": "Local Rules",
          "ewtn-local-rules-subtitle": "You are the local.  Be cool. Make people love your city.",
          "ewtn-local-rule-1": "You pick out where you're going to eat. Make it a public place.",
          "ewtn-local-rule-2": "Tell the visitor how to get there. You should never arrange transportation for them.",
          "ewtn-local-rule-3": "You tell the visitor how much it costs.  Send them the menu with prices to make it easy. No surprises.",
          "ewtn-local-rule-4": "You don't bring any other guests or charge the locals for anything more than what you eat and they eat.  This isn't a way to feed your whole family.  Be cool.",
          "ewtn-local-rule-5": "You are truthful on what languages you speak. The visitor will tell you what language they would like to speak.  Speak that langague.",
          "ewtn-local-rule-6": "You Show up on time! None of this \"People-in-my-culture\" excuses.",
          "ewtn-local-rule-7": "You are not selling anything. Don't try to convince them to take your taxi or your tour. This is their experience here. They just want to meet a good person.",
          "ewtn-local-rule-8": "You get free food.",
          "ewtn-local-rule-9": "Be cool. Be kind.",
          "ewtn-local-get-started": "Get Started as a Local",
          "ewtn-local-howto": "Make a Guide profile and let people know you are available to meet up for food. You can then tell them the best way to contact you. This could be your favorite social media or a messaging app. It's super simple and will take a few minutes.",
          "ewtn-faq-q1": "How much does it cost?",
          "ewtn-faq-a1": "The visitor pays for the food. That is it. It can be as simple as coffee or an elaborate meal. That's up for you all to decide. The native can tell you what they would do. There is no fee for this site.",
          "ewtn-faq-q2": "Why are you doing this?",
          "ewtn-faq-a2": "I read the book \"The Good Life\" in which I learned that \"A good life is built with good relationships\". I found this fascinating in that the WHO has recently declared: \"that one in four people experience social isolation, and one in six adolescents is socially isolated and lonley, though this is likely an underestimation.\" If good relationships are the key to a good life, and loneliness is a major problem, then maybe I could help bring people together. I'm not a therapist, but I can help people meet each other. I can help people connect. I can help people have a good time. I can help people have a good life.",
          "ewtn-faq-q3": "What is the difference between doing this versus Tender, Hinge, or Couchsurfing?",
          "ewtn-faq-a3": "This is not a dating app. This is for all kinds of people. A local might be invited by their a family to join them for dinner. It's not limited to singles. For now, transactions happen via social media channels until I can build something better.",
          "ewtn-faq-q4": "How can I ensure my safety?",
          "ewtn-faq-a4": "See our \"Safety\" page for more information. tl;dr: Trust your instincts. Be wise. Do research. Know what you are getting into.",

          "location-experiences-guided-tours": "Experiences and Guided Tours",
          "location-no-experiences": "Sorry, we could not find any experiences at this location. Check back soon!",
          "location-no-information": "Sorry, we could not find that location. Check back soon!",
          "location-ewtn": "Contact these locals below on their social media profiles to arrange a meal with them. They are your tour guide!",

          "tourguide-get-started": "Get Started by creating your Guide Profile",
          "tourguide-get-started-paragraph": "You don't have to offer tours to be a guide. You can just be a local who wants to meet people from around the world.",
          "tourguide-create-guide-profile": "Create Guide Profile",

          "footer-about": "About Me",
          "footer-safety": "Safety",
          "footer-privacy": "Privacy",
          "footer-socials": "The Socials",
          "footer-tagline": "A global platform where locals shows you around. Make connections. Be cool.",


        }
      },
      it: {
        translation: {
          "signup": "Registrati",
          "signin": "Accedi",
          "signin-already-have-account": "Hai già un account?",
          "signin-with-google": "Accedi con Google",
          "signin-no-account": "Nessun account?",
          "signin-forgot-password": "Password dimenticata?",
          "update-profile": "Aggiorna profilo",
          "become-tourguide": "Diventa una guida turistica!",
          "search-go": "Vai!",
          "search-random": "Casuale",
          "search-placeholder": "Cerca destinazioni",

          "home-hero-title": "Vai locale nella tua prossima avventura",
          "home-hero-subtitle": "Torgyd è una piattaforma globale per guide turistiche indipendenti per offrirti un'esperienza locale indimenticabile",
          "home-signup-ad": "Iscriviti per essere una guida turistica, offri incredibili avventure, incontra persone di tutto il mondo e guadagna denaro.",

          "ewtn-title": "Mangia con i Nativi",
          "ewtn-subtitle": "Al tuo prossimo viaggio, fai una connessione locale.",
          "ewtn-rules": "Le regole",
          "ewtn-whatnot": "Questa non è un'app di incontri. È un modo divertente di incontrare qualcuno di un altro posto. È per viaggiatori solitari, coppie, famiglie e gruppi.",
          "ewtn-visitor-rules": "Regole per i visitatori",
          "ewtn-visitor-rules-subtitle": "Sei un visitatore. Sii simpatico. Lascia a casa quella mentalità etnocentrica.",
          "ewtn-visitor-rule-1": "Questo pasto è per te. Si prevede che tu compri il pasto al locale. Devi pagare solo per un locale!",
          "ewtn-visitor-rule-2": "Dici al locale quanto sei disposto a pagare e vedi se può accontentarti.",
          "ewtn-visitor-rule-3": "Fai sapere al locale quanti sono nel tuo gruppo. Forse sei un viaggiatore solitario o un gruppo di 4 persone che cercano di conoscere qualcuno in città.",
          "ewtn-visitor-rule-4": "Dici al locale quale lingua parli. Forse sei inglese ma vuoi parlare in spagnolo durante il pasto per esercitarti. Fagli sapere.",
          "ewtn-visitor-rule-5": "Organizzi il tuo trasporto.",
          "ewtn-visitor-rule-6": "Arrivi in orario! Niente scuse del tipo \"Persone nella mia cultura\".",
          "ewtn-visitor-rule-7": "Sii simpatico. Sii gentile.",
          "ewtn-visitor-get-started": "Inizia come visitatore",
          "ewtn-visitor-howto": "Cerca una città e vedi chi è disponibile. Guarda i loro profili sui social media e contattali da quei canali.",
          "ewtn-local-rules": "Regole locali",
          "ewtn-local-rules-subtitle": "Sei il locale. Sii simpatico. Fai innamorare le persone della tua città.",
          "ewtn-local-rule-1": "Scegli dove andrai a mangiare. Fai un posto pubblico.",
          "ewtn-local-rule-2": "Dì al visitatore come arrivarci. Non dovresti mai organizzare il trasporto per loro.",
          "ewtn-local-rule-3": "Dici al visitatore quanto costa. Invia loro il menu con i prezzi per renderlo facile. Nessuna sorpresa.",
          "ewtn-local-rule-4": "Non porti altri ospiti o addebiti ai locali nulla più di quello che mangi e mangiano. Questo non è un modo per sfamare tutta la tua famiglia. Sii simpatico.",
          "ewtn-local-rule-5": "Sei sincero su quali lingue parli. Il visitatore ti dirà in quale lingua vorrebbe parlare. Parla quella lingua.",
          "ewtn-local-rule-6": "Arrivi in orario! Niente scuse del tipo \"Persone nella mia cultura\".",
          "ewtn-local-rule-7": "Non stai vendendo nulla. Non cercare di convincerli a prendere il tuo taxi o il tuo tour. Questa è la loro esperienza qui. Vogliono solo incontrare una brava persona.",
          "ewtn-local-rule-8": "Ricevi cibo gratis.",
          "ewtn-local-rule-9": "Sii simpatico. Sii gentile.",
          "ewtn-local-get-started": "Inizia come locale",
          "ewtn-local-howto": "Crea un profilo Guida e fai sapere alle persone che sei disponibile per incontrarti per mangiare. Puoi quindi dirgli il modo migliore per contattarti. Potrebbe essere il tuo social media preferito o un'app di messaggistica. È super semplice e richiederà alcuni minuti.",
          "ewtn-faq-q1": "Quanto costa?",
          "ewtn-faq-a1": "Il visitatore paga il cibo. Questo è tutto. Può essere semplice come un caffè o un pasto elaborato. Sta a voi decidere. Il nativo può dirti cosa farebbe. Non c'è alcuna commissione per questo sito.",
          "ewtn-faq-q2": "Perché stai facendo questo?",
          "ewtn-faq-a2": "Ho letto il libro \"The Good Life\" in cui ho imparato che \"Una buona vita è costruita con buone relazioni\". Ho trovato questo affascinante in quanto l'OMS ha recentemente dichiarato: \"che una persona su quattro sperimenta l'isolamento sociale e uno su sei gli adolescenti è socialmente isolato e solitario, anche se questo è probabilmente un sottostima.\" Se le buone relazioni sono la chiave per una buona vita e la solitudine è un problema importante, allora forse potrei aiutare a far incontrare le persone. Non sono uno psicoterapeuta, ma posso aiutare le persone a incontrarsi. Posso aiutare le persone a connettersi. Posso aiutare le persone a divertirsi. Posso aiutare le persone a vivere una buona vita.",
          "ewtn-faq-q3": "Qual è la differenza tra fare questo rispetto a Tender, Hinge o Couchsurfing?",
          "ewtn-faq-a3": "Questa non è un'app di incontri. Questo è per tutti i tipi di persone. Un locale potrebbe essere invitato dalla sua famiglia a unirsi a loro per cena. Non è limitato ai single. Per ora, le transazioni avvengono tramite i canali dei social media fino a quando non posso costruire qualcosa di meglio.",
          "ewtn-faq-q4": "Come posso garantire la mia sicurezza?",
          "ewtn-faq-a4": "Vedi la nostra pagina \"Sicurezza\" per maggiori informazioni. tl; dr: Fidati del tuo istinto. Sii saggio. Fai ricerche. Sappi a cosa stai andando incontro.",

          "location-experiences-guided-tours": "Esperienze e tour guidati",
          "location-no-experiences": "Spiacenti, non siamo riusciti a trovare esperienze in questa posizione. Torna presto!",
          "location-no-information": "Spiacenti, non siamo riusciti a trovare quella posizione. Torna presto!",
          "location-ewtn": "Contatta questi locali qui sotto sui loro profili sui social media per organizzare un pasto con loro. Sono la tua guida!",

          "tourguide-get-started": "Inizia creando il tuo profilo Guida",
          "tourguide-get-started-paragraph": "Non devi offrire tour per essere una guida. Puoi essere solo un locale che vuole incontrare persone di tutto il mondo.",
          "tourguide-create-guide-profile": "Crea profilo guida",


          "footer-about": "Chi sono",
          "footer-safety": "Sicurezza",
          "footer-privacy": "Privacy",
          "footer-socials": "I social",
          "footer-tagline": "Una piattaforma globale in cui i locali ti mostrano in giro. Fai connessioni. Sii simpatico.",

        }
      },
      es: {
        translation: {
          "signup": "Regístrarse",
          "signin": "Iniciar sesión",
          "signin-already-have-account": "¿Ya tienes una cuenta?",
          "signin-with-google": "Iniciar sesión con Google",
          "signin-no-account": "¿No tienes una cuenta?",
          "signin-forgot-password": "¿Olvidaste tu contraseña?",
          "update-profile": "Actualizar perfil",
          "become-tourguide": "¡Conviértete en un guía turístico!",
          "search-go": "¡Ir!",
          "search-random": "Aleatorio",
          "search-placeholder": "Buscar destinos",

          "home-hero-title": "Vaya local en su próxima aventura",
          "home-hero-subtitle": "Torgyd es una plataforma global para guías turísticos independientes para brindarle una experiencia local inolvidable",
          "home-signup-ad": "Regístrese para ser un guía turístico, ofrezca aventuras increíbles, conozca gente de todo el mundo y gane dinero.",

          "ewtn-title": "Come con los nativos",
          "ewtn-subtitle": "En su próximo viaje, haga una conexión local.",
          "ewtn-rules": "Las reglas",
          "ewtn-whatnot": "Esta no es una aplicación de citas. Es una forma divertida de conocer a alguien de otro lugar. Es para viajeros solos, parejas, familias y grupos.",
          "ewtn-visitor-rules": "Reglas para visitantes",
          "ewtn-visitor-rules-subtitle": "Eres un visitante. Sé genial. Deja esa mentalidad etnocéntrica en casa.",
          "ewtn-visitor-rule-1": "Esta comida es para ti. Se espera que compre la comida local. ¡Solo tienes que pagar por un local!",
          "ewtn-visitor-rule-2": "Dile al local cuánto estás dispuesto a pagar y ve si puede acomodarte.",
          "ewtn-visitor-rule-3": "Hazle saber al local cuántos hay en tu grupo. Quizás seas un viajero solitario o un grupo de 4 personas que buscan conocer a alguien en la ciudad.",
          "ewtn-visitor-rule-4": "Dile al local qué idioma hablas. Quizás seas inglés pero quieras hablar en español durante la comida para practicar. Hazle saber.",
          "ewtn-visitor-rule-5": "Organizas tu propio transporte.",
          "ewtn-visitor-rule-6": "¡Llegas a tiempo! Ninguna de estas excusas \"Personas en mi cultura\".",
          "ewtn-visitor-rule-7": "Sé genial. Sé amable.",
          "ewtn-visitor-get-started": "Comience como visitante",
          "ewtn-visitor-howto": "Busque una ciudad y vea quién está disponible. Mira sus perfiles de redes sociales y contáctelos desde esos canales.",
          "ewtn-local-rules": "Reglas locales",
          "ewtn-local-rules-subtitle": "Eres el local. Sé genial. Haz que la gente ame tu ciudad.",
          "ewtn-local-rule-1": "Elige dónde vas a comer. Haz un lugar público.",
          "ewtn-local-rule-2": "Dile al visitante cómo llegar allí. Nunca debe organizar el transporte para ellos.",
          "ewtn-local-rule-3": "Dile al visitante cuánto cuesta. Envíeles el menú con los precios para que sea fácil. Sin sorpresas.",
          "ewtn-local-rule-4": "No traes otros invitados o cobras a los lugareños nada más que lo que comes y ellos comen. Esta no es una forma de alimentar a toda tu familia. Sé genial.",
          "ewtn-local-rule-5": "Eres sincero sobre qué idiomas hablas. El visitante te dirá en qué idioma le gustaría hablar. Habla ese idioma.",
          "ewtn-local-rule-6": "¡Llegas a tiempo! Ninguna de estas excusas \"Personas en mi cultura\".",
          "ewtn-local-rule-7": "No estás vendiendo nada. No intentes convencerlos de que tomen tu taxi o tu tour. Esta es su experiencia aquí. Solo quieren conocer a una buena persona.",
          "ewtn-local-rule-8": "Recibes comida gratis.",
          "ewtn-local-rule-9": "Sé genial. Sé amable.",
          "ewtn-local-get-started": "Comience como local",
          "ewtn-local-howto": "Cree un perfil de guía y hágales saber que está disponible para reunirse para comer. Luego puede decirles la mejor manera de contactarlo. Esto podría ser su red social favorita o una aplicación de mensajería. Es súper simple y tomará unos minutos.",
          "ewtn-faq-q1": "¿Cuánto cuesta?",
          "ewtn-faq-a1": "El visitante paga la comida. Eso es todo. Puede ser tan simple como un café o una comida elaborada. Eso depende de ustedes. El nativo puede decirte qué harían. No hay tarifa por este sitio.",
          "ewtn-faq-q2": "¿Por qué estás haciendo esto?",
          "ewtn-faq-a2": "Leí el libro \"The Good Life\" en el que aprendí que \"Una buena vida se construye con buenas relaciones\". Me pareció fascinante que la OMS haya declarado recientemente: \"que una de cada cuatro personas experimenta aislamiento social y uno de cada seis adolescentes está socialmente aislado y solo, aunque esto es probablemente una subestimación.\" Si las buenas relaciones son la clave para una buena vida y la soledad es un problema importante, entonces tal vez podría ayudar a que las personas se unan. No soy terapeuta, pero puedo ayudar a las personas a conocerse. Puedo ayudar a las personas a conectarse. Puedo ayudar a las personas a pasar un buen rato. Puedo ayudar a las personas a tener una buena vida.",
          "ewtn-faq-q3": "¿Cuál es la diferencia entre hacer esto versus Tender, Hinge o Couchsurfing?",
          "ewtn-faq-a3": "Esta no es una aplicación de citas. Esto es para todo tipo de personas. Un local podría ser invitado por su familia a cenar con ellos. No se limita a solteros. Por ahora, las transacciones se realizan a través de canales de redes sociales hasta que pueda construir algo mejor.",
          "ewtn-faq-q4": "¿Cómo puedo asegurar mi seguridad?",
          "ewtn-faq-a4": "Consulte nuestra página \"Seguridad\" para obtener más información. tl; dr: Confía en tus instintos. Sé sabio. Hacer investigación. Sepa en qué se está metiendo.",

          "location-experiences-guided-tours": "Experiencias y visitas guiadas",
          "location-no-experiences": "Lo sentimos, no pudimos encontrar experiencias en esta ubicación. ¡Vuelve pronto!",
          "location-no-information": "Lo sentimos, no pudimos encontrar esa ubicación. ¡Vuelve pronto!",
          "location-ewtn": "Póngase en contacto con estos locales a continuación en sus perfiles de redes sociales para organizar una comida con ellos. ¡Son tu guía!",

          "tourguide-get-started": "Comience creando su perfil de guía",
          "tourguide-get-started-paragraph": "No tienes que ofrecer tours para ser una guía. Puede ser solo un local que quiere conocer gente de todo el mundo.",
          "tourguide-create-guide-profile": "Crear perfil de guía",

          "footer-about": "Sobre mí",
          "footer-safety": "Seguridad",
          "footer-privacy": "Privacidad",
          "footer-socials": "Los sociales",
          "footer-tagline": "Una plataforma global donde los lugareños te muestran. Haz conexiones. Sé genial.",
        }
      }
    },
    lng: "en", // Default language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

