export const FETCH = 'FETCH';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export const GET_LOCATION = 'GET_LOCATION';
export const GET_LOCATION_VIEW = 'GET_LOCATION_VIEW';
export const GET_TOUR = 'GET_TOUR';
export const GOT_RESPONSE = 'GOT_RESPONSE';
export const ERROR = 'ERROR';
/* data type methods */
export const ME = 'ME';
export const CLEAR_ME = 'CLEAR_ME';

export const GET_TOURS = 'GET_TOURS';

/* tour crud options */
export const CREATE_TOUR = 'CREATE_TOUR';
export const GET_MYTOUR = 'GET_MYTOUR';
export const EDIT_TOUR = 'EDIT_TOUR';
export const DELETE_TOUR = 'DELETE_TOUR';
export const CLEAR_MYTOUR = 'CLEAR_MYTOUR';

export const GET_LOCATIONS = 'GET_LOCATIONS';

export const EDIT_TOURGUIDE = 'EDIT_TOURGUIDE';
export const LOCAL_EDIT_TOURGUIDE = 'LOCAL_EDIT_TOURGUIDE'; // used when we change the tourguide locally. 
export const CREATE_TOURGUIDE = 'CREATE_TOURGUIDE';
export const GET_MY_TOURGUIDE_PROFILE = 'GET_MY_TOURGUIDE_PROFILE';
export const GET_PRESIGNED_IMAGE_URLS = 'GET_PRESIGNED_IMAGE_URLS';
export const CLEAR_PRESIGNED_IMAGE_URLS = 'CLEAR_PRESIGNED_IMAGE_URLS'; // after we create the images

export const UPLOAD_FILE_TO_S3 = 'UPLOAD_FILE_TO_S3';