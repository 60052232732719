
export const displayCostType = (costType) => {
    switch (costType) {
        case "PER_PERSON":
            return "person";
        case "PER_TOUR":
            return "tour"
        default:
            return costType;
    }
}
