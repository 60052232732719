import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../layout";

const BecomeAGuide = () => {
    return (
        <>
            <h1>Become a Tour Guide!</h1>
            <ul>
                <li>Earn extra money</li>
                <li>Work on your own schedule</li>
                <li>Enjoy new people.</li>
            </ul>
            This isn't just another gig economy job.
            <br />
            This is a chance to share your knowledge and passion with others.
            <br />
            <br />
            <b>Think about it...</b>
            <ul>
                <li>You don't have to buy any expensive cars or homes.</li>
                <li>You can be your own brand.</li>
                <li>You have ultimate freedom in how you do your job.</li>
            </ul>

            <h2>What is normal to you is an adventure to someone else!</h2>
            <p>
                Think of something cool you do in your own hometown.
                Maybe you know a lot about the history of your town.
                Maybe you know the best places to eat.
                Maybe you know the best places to go for a hike.
                Maybe you know the best places to go for a bike ride.
                Maybe you know the best places to go for a swim.
                Maybe you know the best places to go for a picnic.
                <br />
                Whatever it is, you know it!  So share it with others and make some money doing it!

            </p>

            <h1>How it works.</h1>

            <h2>1. Create a Tour</h2>
            <p>
                It's easy to create a tour.  Just Sign up and create a tour here.
            </p>

            <h2>2. Share your Tour</h2>
            <ul>
                <li>We can provide an easy place for people to upload their experiences.</li>
                <li>Get reviews to help you get more exposure.</li>
            </ul>

            <h2>3. Give them a thrill.</h2>
            <ul>
                <li>Our scheduling software provides everything you need to connect with your customers.</li>
                <li>Show up and let us take care of the payment, you do nothing but show your charm.</li>
            </ul>


            <h1>This is so good, but what's in it for torgyd?</h1>
            <ul>
                <li>Our mission is to become the largest source of experiences for leisure travelers.</li>
                <li>You can use our payment system or not.  If so we take only 5% of each transaction. 2.5% goes to our payment provider.</li>
            </ul>
        </>
    )
}

const TorgydPhilosophy = () => {
    return (
        <>
            <h1>Our Philosophy</h1>
            We have a few quirks about how we show experiences to users.
            This makes it easy for users to find what they are looking for.
            It also helps you plan your tour and keep it a local experience.
            We love the big tour operators but we're looking to offer something different.
            <br />
            <br />
            <ul>
                <li>All tours are either <b>Small</b>, <b>Medium</b>, or <b>Large</b>.  There are only three types.
                    This helps guide seekers to compare and find the best experience. This sizing has regulations on how long tours are
                    and what they must include.
                    <ul>
                        <li>Small tours are 1-3 hours and include a snack.</li>
                        <li>Medium tours are 3-5 hours and include a meal.</li>
                        <li>Large tours are 5-8 hours and include two meals.</li>
                    </ul>
                </li>
                <br />
                <li>
                    All tours are either <b>Couch</b>, <b>In Between</b>, or <b>Extreme</b>.  This helps guide seekers
                    sign up for something they are comfortable to experience. It also helps you ensure the people
                    that sign up are able to go at your pace.
                </li>
                <br />
                <li>
                    <b>No Tipping</b> and <b>No Extra/Hidden Fees</b>.  We don't know what has happened to this world but
                    somewhere along the line some greedy corporations decided they wouldn't pay their employees a living wage.
                    They came up with this idea that the customer should pay the employee directly.  This is a terrible idea. It's confusing
                    and it's not fair to the employee.  With torgyd you run your business and you charge the customer a fair price.  That's it.
                    No hidden fees.  No tipping fees. No service fees.  Since we don't ask you to tip us to use our platform, we ask that
                    you don't ask your customers to tip you.  We're all in this together.
                </li>
            </ul>
        </>
    )
}

const GetStarted = ({ me }) => {
    return (
        <>
            <h1>Getting Started</h1>

            <div className="align-items-center justify-content-center d-flex flex-column">
                {me ?
                    <>
                        Edit your profile and start your tourguide business.
                        <Link to="/tourguide/edit" className="btn btn-outline-dark">Create/Edit TourGuide Profile</Link>
                        <br />
                        When finished, create your first tour.
                        <Link to="/tourguide/tours/create" className="btn btn-outline-dark">Create Tour</Link>
                    </>
                    :
                    <>
                        <p>
                            The first step is to sign in or create an account.  It can take less than 10 seconds.  Just
                            sign in with Google or use a username and password. Come back here when you're done.
                        </p>
                        <Link to="/signup" className="btn btn-outline-dark">Sign Up</Link>
                    </>
                }
            </div>
        </>
    )
}

const PromoteTourGuide = ({ activeTab, me, handleTabClick }) => {
    return (
        <>
            <ul className="nav nav-tabs mb-3">

                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}
                        onClick={() => handleTabClick('tab1')}
                        href="#tab1"
                    >
                        Become A Guide
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`}
                        onClick={() => handleTabClick('tab2')}
                        href="#tab2"
                    >
                        torgyd Philosophy
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`}
                        onClick={() => handleTabClick('tab3')}
                        href="#tab3"
                    >
                        Get Started
                    </a>
                </li>
            </ul>

            {activeTab === 'tab1' && <BecomeAGuide />}
            {activeTab === 'tab2' && <TorgydPhilosophy />}
            {activeTab === 'tab3' && <GetStarted me={me} />}
        </>
    )
}



const TourGuideBrochure = () => {
    const [activeTab, setActiveTab] = React.useState('tab1')
    const me = useSelector(state => state.API.me)

    const handleTabClick = (tab) => {
        setActiveTab(tab)
    }

    useEffect(() => {
        // Check if the URL has a hash and update the active tab accordingly
        const hash = window.location.hash;
        if (hash) {
            const tab = hash.slice(1); // Remove the '#' character
            setActiveTab(tab);
        }

    }, []); // Run this effect only once on component mount

    return (
        <Layout selected="tourguide">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                        <Link to="/tourguide">TourGuide</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">Become A Tour Guide!</li>
                </ol>
            </nav>
            <PromoteTourGuide activeTab={activeTab} me={me} handleTabClick={handleTabClick} />
        </Layout>
    );
}

export default TourGuideBrochure;