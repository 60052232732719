import React from 'react';
import Layout from './layout';

const About = () => {
    return (
        <Layout selected="about">
            <div className="container min-vh-100">
                <h1>Safety at Torgyd: Our Top Priority</h1>

                <p>
                    At torgyd, we are committed to creating a safe and trustworthy environment for tourists and locals alike to connect and share unique dining experiences.
                    Your safety is our highest priority, and we encourage all users to prioritize it as well in every interaction.
                </p>

                <h4>Connect with Confidence</h4>
                <p>
                    <b>Social Media Profiles:</b> All connections on our platform are initiated through social media, providing a layer of transparency.
                    We encourage users to review social media profiles thoroughly before agreeing to meet.
                    This step is crucial in establishing trust and ensuring mutual comfort.
                </p>

                <p>
                    <b>Transparent Communication:</b> Clear and open communication is key. Discuss your plans in detail, share expectations, and do not hesitate to ask questions.
                    Always inform someone you trust about your plans and where you will be.
                </p>

                <h4>
                    Meeting Safely
                </h4>
                <p>
                    <b>Choose Public Venues:</b> For all meetups, whether it's for coffee, a meal, or a drink, always choose public and well-populated places.
                    Restaurants, cafes, and bars in central locations are ideal.
                    These settings not only ensure safety but also contribute to a relaxed and enjoyable experience.
                </p>
                <p>
                    <b>Be Informed About the Locale:</b> Familiarize yourself with the meeting location beforehand.
                    Know the area's general safety, cultural norms, and public transport options.
                    If you're a tourist, take the time to research the area; if you're a local, choose a location you're comfortable with and share any relevant information with your guest.
                </p>
                <p>
                    <b>Stay Sober and Alert:</b> While enjoying your time, it's important to stay alert and maintain control of your situation.
                    Limit alcohol consumption and avoid substances that could impair your judgment.
                </p>

                <h4>
                    In Case of Emergency
                </h4>
                <p>
                    <b>Emergency Contacts:</b> Always have a list of emergency contacts, including local authorities and your embassy (if you're a tourist).
                    Do not rely solely on your host or guest for this information.
                </p>
                <p>
                    <b>Trust Your Instincts:</b> If at any point you feel uncomfortable or unsafe, trust your instincts and do not hesitate to end the meeting politely but firmly.
                    Your safety is more important than social niceties.
                </p>

                <h4>
                    After Your Meeting
                </h4>
                <p>
                    <b>Leave Feedback and Reviews:</b> After your meeting, we encourage you to leave feedback.
                    Your reviews help us maintain a safe community and assist others in making informed decisions.
                </p>

                <p className="mt-3">Safety is a shared responsibility, and by following these guidelines, we believe you can have enjoyable and memorable experiences through torgyd.
                    Should you have any concerns or need assistance, our support team is always here to help.
                </p>
            </div>
        </Layout>
    );
}

export default About;