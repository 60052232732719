import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { doFetch } from "../../redux/api/actions";
import { GET_LOCATIONS, GET_MY_TOURGUIDE_PROFILE, GET_TOURS } from "../../redux/api/constants";
import ProfileView from "./profile";
import Layout from "../layout";
import { useTranslation } from "react-i18next";

const TourGuideHome = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const accessToken = useSelector(state => state.Auth.accessToken);
    const loading = useSelector(state => state.API.loading);
    const tourGuideProfile = useSelector(state => state.API.tourguide);
    const tours = useSelector(state => state.API.tours);
    const locations = useSelector(state => state.API.locations);

    const [activeTab, setActiveTab] = React.useState('tab1')

    const handleTabClick = (tab) => {
        setActiveTab(tab)
    }

    useEffect(() => {
        dispatch(doFetch(
            GET_MY_TOURGUIDE_PROFILE,
            "tourguide/me",
            accessToken,
        ))
        dispatch(doFetch(
            GET_TOURS,
            "tourguide/tours",
            accessToken,
        ))
        dispatch(doFetch(GET_LOCATIONS, "locations"))
    }, [dispatch, accessToken]);

    useEffect(() => {
        // Check if the URL has a hash and update the active tab accordingly
        const hash = window.location.hash;
        if (hash) {
            const tab = hash.slice(1); // Remove the '#' character
            setActiveTab(tab);
        }

    }, []); // Run this effect only once on component mount

    return (
        <Layout selected="tourguide">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item" aria-current="page">
                        <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        TourGuide
                    </li>
                </ol>
            </nav>

            {!loading &&
                (
                    tourGuideProfile ?
                        <ProfileView tourGuideProfile={tourGuideProfile}
                            activeTab={activeTab}
                            tours={tours}
                            locations={locations}
                            handleTabClick={handleTabClick} />
                        :
                        <div className="text-center">
                            <p>
                                {t('tourguide-get-started')}
                            </p>
                            <p>
                                {t('tourguide-get-started-paragraph')}
                            </p>
                            <Link to="/tourguide/edit" className="btn btn-outline-dark btn-lg">{t('tourguide-create-guide-profile')}</Link>
                        </div>
                )
            }
        </Layout>
    );
}

export default TourGuideHome;