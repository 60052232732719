import {
	FETCH,
	POST,
	ERROR,
	GOT_RESPONSE,
	ME,
	CREATE_TOUR,
	GET_TOURS,
	GET_MYTOUR,
	GET_LOCATIONS,
	EDIT_TOURGUIDE,
	CREATE_TOURGUIDE,
	EDIT_TOUR,
	GET_MY_TOURGUIDE_PROFILE,
	GET_PRESIGNED_IMAGE_URLS,
	GET_LOCATION_VIEW,
	UPLOAD_FILE_TO_S3,
	PUT,
	DELETE,
	DELETE_TOUR,
	CLEAR_MYTOUR,
	CLEAR_ME,
	GET_TOUR,
	LOCAL_EDIT_TOURGUIDE,
	CLEAR_PRESIGNED_IMAGE_URLS
} from './constants';

const initState = {
	loading: false,
	me: null,
	categories: [],
	recipes: [],
	error: null,
	tours: [],
	tour: null,
	myTour: null,
	tourguide: null,
	message: null,
	locations: [],
	locationView: [],
	presignedImageUrls: [],
}

const API = (state = initState, action) => {
	switch (action.type) {
		case FETCH:
		case POST:
		case PUT:
		case DELETE:
			return { ...state, loading: true, error: null, message: null }
		case UPLOAD_FILE_TO_S3:
			return { ...state, loading: true, error: null, message: null }
		case CLEAR_MYTOUR:
			return { ...state, myTour: null }
		case CLEAR_ME:
			return { ...state, me: null }
		case GOT_RESPONSE:
			if (action.responseType === ME) {
				return { ...state, loading: false, error: null, me: action.response }
			} else if (action.responseType === CREATE_TOUR) {
				return { ...state, loading: false, error: null, myTour: action.response, message: "Tour Created", presignedImageUrls: [] }
			} else if (action.responseType === EDIT_TOUR) {
				return { ...state, loading: false, error: null, myTour: action.response, message: "Tour Updated", presignedImageUrls: [] }
			} else if (action.responseType === GET_MYTOUR) {
				return { ...state, loading: false, error: null, myTour: action.response }
			} else if (action.responseType === GET_TOURS) {
				return { ...state, loading: false, error: null, tours: action.response.tours }
			} else if (action.responseType === GET_LOCATIONS) {
				return { ...state, loading: false, error: null, locations: action.response.locations }
			} else if (action.responseType === EDIT_TOURGUIDE || action.responseType === CREATE_TOURGUIDE) {
				return { ...state, loading: false, error: null, tourguide: action.response, message: "Tourguide Updated" }
			} else if (action.responseType === GET_MY_TOURGUIDE_PROFILE) {
				return { ...state, loading: false, error: null, tourguide: action.response }
			} else if (action.responseType === DELETE_TOUR) {
				return { ...state, loading: false, error: null, message: "Tour Deleted", myTour: null }
			} else if (action.responseType === GET_PRESIGNED_IMAGE_URLS) {
				return { ...state, loading: false, error: null, presignedImageUrls: action.response.presigned_urls }
			} else if (action.responseType === UPLOAD_FILE_TO_S3) {
				return { ...state, loading: false, error: null, message: "Image Uploaded" }
			} else if (action.responseType === GET_LOCATION_VIEW) {
				return { ...state, loading: false, error: null, locationView: action.response.locations }
			} else if (action.responseType === GET_TOUR) {
				return { ...state, loading: false, error: null, tour: action.response }
			}
			return { ...state, loading: false, error: `Unimplemented Error: ${action}` }
		case LOCAL_EDIT_TOURGUIDE:
			return { ...state, loading: false, error: null, tourguide: action.tourguide }
		case CLEAR_PRESIGNED_IMAGE_URLS:
			return { ...state, presignedImageUrls: [] }
		case ERROR:
			return { ...state, loading: false, error: action.error }
		default:
			return state;

	}
};

export default API;