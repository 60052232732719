import React, { useEffect } from "react";
import validator from 'validator';
import ImageUploader from "../imageUploader";
import { Link } from "react-router-dom";

const languageOptions = [
    { value: 'english', label: 'English' },
    { value: 'spanish', label: 'Spanish' },
    { value: 'french', label: 'French' },
    { value: 'german', label: 'German' },
    { value: 'italian', label: 'Italian' },
    { value: 'portuguese', label: 'Portugeuse' },
    { value: 'chinese', label: 'Mandarin Chinese' },
    { value: 'russian', label: 'Russian' },
    { value: 'korean', label: 'Korean' },
    { value: 'japanese', label: 'Japanese' },
    { value: 'arabic', label: 'Arabic' },
    { value: 'hebrew', label: 'Hebrew' },
]

const eatWithLocalOptions = [
    { value: 'coffee', label: 'Coffee' },
    { value: 'breakfast', label: 'Breakfast' },
    { value: 'lunch', label: 'Lunch' },
    { value: 'dinner', label: 'Dinner' },
    { value: 'drinks', label: 'Drinks' },
]


const TourGuideForm = ({ me, locations, formSubmitFunc, tourguide, imageUploadFunc }) => {

    const [name, setName] = React.useState(tourguide && tourguide.name ? tourguide.name : "");
    const [nameError, setNameError] = React.useState(null);

    const [avatarUrl, setAvatarUrl] = React.useState((tourguide && tourguide.avatar_url) ? tourguide.avatar_url : "");

    const [bio, setBio] = React.useState(tourguide && tourguide.bio ? tourguide.bio : "");
    const [bioError, setBioError] = React.useState(null);

    const [eatWithLocal, setEatWithLocal] = React.useState(tourguide && tourguide.eat_with_local ? tourguide.eat_with_local : false);
    const [eatWithLocalError, setEatWithLocalError] = React.useState(null);

    const [eatWithLocalPreferences, setEatWithLocalPreferences] = React.useState(tourguide && tourguide.eat_with_local_preferences ? tourguide.eat_with_local_preferences.split(",") : []);
    const [eatWithLocalPreferencesError, setEatWithLocalPreferencesError] = React.useState(null);

    const [location, setLocation] = React.useState(tourguide && tourguide.location_id ? tourguide.location_id : "");
    const [locationError, setLocationError] = React.useState(null);

    const [languages, setLanguages] = React.useState(tourguide && tourguide.languages ? tourguide.languages.split(",") : []);
    const [languagesError, setLanguagesError] = React.useState(null);

    const [phone, setPhone] = React.useState(tourguide && tourguide.phone ? tourguide.phone : "");
    const [phoneError, setPhoneError] = React.useState(null);

    const [website, setWebsite] = React.useState(tourguide && tourguide.webpage ? tourguide.webpage : "");
    const [websiteError, setWebsiteError] = React.useState(null);

    const [facebook, setFacebook] = React.useState(tourguide && tourguide.facebook ? tourguide.facebook : "");
    const [facebookError, setFacebookError] = React.useState(null);

    const [instagram, setInstagram] = React.useState(tourguide && tourguide.instagram ? tourguide.instagram : "");
    const [instagramError, setInstagramError] = React.useState(null);

    const [twitter, setTwitter] = React.useState(tourguide && tourguide.twitter ? tourguide.twitter : "");
    const [twitterError, setTwitterError] = React.useState(null);

    const [linkedin, setLinkedin] = React.useState(tourguide && tourguide.linkedin ? tourguide.linkedin : "");
    const [linkedinError, setLinkedinError] = React.useState(null);

    const [tiktok, setTiktok] = React.useState(tourguide && tourguide.tiktok ? tourguide.tiktok : "");
    const [tiktokError, setTiktokError] = React.useState(null);

    useEffect(() => {
        if (tourguide) {
            setName(tourguide.name || "")
            setBio(tourguide.bio || "")
            setLocation(tourguide.location_id || "")
            setPhone(tourguide.phone || "")
            //setWebsite(tourguide.webpage || "")
            setFacebook(tourguide.facebook || "")
            setInstagram(tourguide.instagram || "")
            setTwitter(tourguide.twitter || "")
            setLinkedin(tourguide.linkedin || "")
            setTiktok(tourguide.tiktok || "")
            setAvatarUrl(tourguide.avatar_url || "")
            setLanguages((tourguide.languages && tourguide.languages.split(",")) || "")
            setEatWithLocal(tourguide.eat_with_local || false)
            setEatWithLocalPreferences((tourguide.eat_with_local_preferences && tourguide.eat_with_local_preferences.split(",")) || [])
        }
    }, [tourguide])

    const validateAndSubmit = (e) => {
        e.preventDefault();
        setNameError(null)
        setBioError(null)
        setLocationError(null)
        setFacebookError(null)
        setInstagramError(null)
        setTwitterError(null)
        setLinkedinError(null)
        setTiktokError(null)
        setWebsiteError(null)
        setPhoneError(null)
        setLanguagesError(null)
        setEatWithLocalError(null)
        setEatWithLocalPreferencesError(null)


        var errors = false;
        /* I don't care about validating phone for now.
        if (phone && !validator.isMobilePhone(phone)) {
                setPhoneError('Please enter a valid phone number.')
            errors = true;
        }
            */
        if (name === "") {
            setNameError('Please enter the name you want shown to people who take your tours.')
            errors = true;
        }

        if (bio === "") {
            setBioError('Ah shucks, you got to tell us about yourself.  How will people get to know you?')
            errors = true;
        }
        if (location === "") {
            setLocationError('Where even are you?')
            errors = true;
        }

        if (languages.length < 1) {
            setLanguagesError('Please let people know what languages you speak.')
            errors = true;
        }

        if (website && !validator.isURL(website)) {
            setWebsiteError('Please enter a valid URL.')
            errors = true;
        }

        if (facebook && !validator.isURL(facebook)) {
            setFacebookError('Please enter a valid URL.')
            errors = true;
        }

        if (instagram && !validator.isURL(instagram)) {
            setInstagramError('Please enter a valid URL.')
            errors = true;
        }

        if (tiktok && !validator.isURL(tiktok)) {
            setTiktokError('Please enter a valid URL.')
            errors = true;
        }
        if (linkedin && !validator.isURL(linkedin)) {
            setLinkedinError('Please enter a valid URL.')
            errors = true;
        }

        if (twitter && !validator.isURL(twitter)) {
            setTwitterError('Please enter a valid URL.')
            errors = true;
        }

        if (!twitter && !facebook && !instagram && !website && !linkedin && !tiktok) {
            setWebsiteError('Please enter at least one way people can contact you.')
            errors = true;
        }

        if (errors) {
            return
        }

        formSubmitFunc({
            name: name,
            avatar_url: avatarUrl,
            languages: languages.join(","),
            user_id: me.id,
            email: me.email,
            location_id: location,
            bio: bio,
            eat_with_local: eatWithLocal,
            eat_with_local_preferences: eatWithLocalPreferences.join(","),
            phone: phone,
            webpage: website,
            facebook: facebook,
            instagram: instagram,
            twitter: twitter,
            linkedin: linkedin,
            tiktok: tiktok
        })
    }

    const handleLanguageChange = (language, checked) => {
        if (checked) {
            // Add the language if it's not already in the array
            if (!languages.includes(language)) {
                setLanguages([...languages, language]);
            }
        } else {
            // Remove the language if it's in the array
            setLanguages(languages.filter((l) => l !== language));
        }
    };

    const handleEatWithLocalChange = (eatWithLocal, checked) => {
        if (checked) {
            // Add the eatWithLocal if it's not already in the array
            if (!eatWithLocalPreferences.includes(eatWithLocal)) {
                setEatWithLocalPreferences([...eatWithLocalPreferences, eatWithLocal]);
            }
        } else {
            // Remove the eatWithLocal if it's in the array
            setEatWithLocalPreferences(eatWithLocalPreferences.filter((l) => l !== eatWithLocal));
        }
    }


    return (
        < form onSubmit={validateAndSubmit} >
            <ImageUploader imageUrls={[avatarUrl]} imageUploadFunc={imageUploadFunc} />
            <fieldset>
                <div className="form-group">
                    <label htmlFor="name" className="form-label">
                        Guide Name
                    </label>
                    <input
                        id="name"
                        className={nameError ?
                            "form-control from-control-lg is-invalid"
                            : "form-control form-control-lg"}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <small id="nameHelp" className="form-text text-muted">This can be your own name, an LLC, or something that people will see when they take your tour.  This is your brand.</small>
                    <div className="invalid-feedback">{nameError}</div>
                </div>
                <div className="form-group">
                    <label htmlFor="selectLocation" className="form-label">
                        Select Location. Where do you abide?
                    </label>
                    <select
                        id="selectLocation"
                        className={locationError ?
                            "form-control from-control-lg form-select is-invalid"
                            : "form-control form-control-lg form-select"}
                        value={location || ''}
                        onChange={(e) => setLocation(e.target.value)}
                    >
                        <option value="" disabled>
                            Select Location
                        </option>
                        {locations.map((location) => (
                            <option key={location.id} value={location.id}>
                                {location.flag} {location.city}, {location.country}
                            </option>
                        ))}
                    </select>
                    <small id="locationHelp" className="form-text text-muted">Don't see your location? <a href="mailto:admin@castlerock.ai">Email Me</a> and I'll add it right away. </small>
                    <div className="invalid-feedback">{locationError}</div>
                </div>
                <div className="form-group">
                    <label className="form-label">
                        What languages do you speak?
                    </label>
                    <div className={languagesError ?
                        "form-control from-control-lg form-select is-invalid checkbox-grid"
                        : "form-control form-control-lg form-select checkbox-grid"}>
                        {languageOptions.map((language, index) => (
                            <div key={index} className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`language-${language.value}`}
                                    value={language.value}
                                    onChange={(e) => handleLanguageChange(language.value, e.target.checked)}
                                    checked={languages.includes(language.value)}
                                />
                                <label className="form-check-label" htmlFor={`language-${language.value}`}>
                                    {language.label}
                                </label>
                            </div>
                        ))}
                    </div>
                    <small id="locationHelp" className="form-text text-muted">
                        Don't see a language you speak? <a href="mailto:admin@castlerock.ai">Email Me</a> and I'll add it right away.
                    </small>
                    <div className="invalid-feedback">{languagesError}</div>
                </div>

                <div className="form-group">
                    <label htmlFor="bio">Bio</label>
                    <textarea id="bio" rows="3"
                        className={bioError ?
                            "form-control form-control-lg is-invalid"
                            : "form-control form-control-lg"}
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}></textarea>
                    <small id="bioHelp" className="form-text text-muted">Tell us about yourself! Tell them why you are amazing.</small>
                    <div className="invalid-feedback">{bioError}</div>
                </div>

                <div className="form-group mt-3 alert alert-info">
                    <input type="checkbox" id="eatWithLocal" className="form-check-input"
                        checked={eatWithLocal}
                        onChange={(e) => setEatWithLocal(e.target.checked)} />
                    <label htmlFor="eatWithLocal" className="form-check-label">
                        I'm willing to be listed and contacted by visitors to my city that want to buy me something to eat or drink.
                    </label>
                    <div>
                        <small id="eatWithLocalHelp" className="form-text text-muted">See the <Link to="/eatwiththenatives">Eat with the Natives</Link> page for more information.
                            This is not a tour and you shouldn't try to sell them anything.  You just need to be a real person and they will buy you a meal or a drink.
                        </small>
                        <div className="invalid-feedback">{eatWithLocalError}</div>
                    </div>
                    {eatWithLocal &&
                        <div className="form-group mt-3">
                            <label className="form-label">
                                What would you prefer to meet up for?
                            </label>
                            <div className={eatWithLocalPreferencesError ?
                                "form-control from-control-lg is-invalid checkbox-grid"
                                : "form-control form-control-lg checkbox-grid"}>
                                {eatWithLocalOptions.map((preference, index) => (
                                    <div key={index} className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`preference-${preference.value}`}
                                            value={preference.value}
                                            onChange={(e) => handleEatWithLocalChange(preference.value, e.target.checked)}
                                            checked={eatWithLocalPreferences.includes(preference.value)}
                                        />
                                        <label className="form-check-label" htmlFor={`preference-${preference.value}`}>
                                            {preference.label}
                                        </label>
                                        <div className="invalid-feedback">{eatWithLocalPreferencesError}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    }
                </div>

                <hr />
                <p className="lead">Cool, now let's get some contact info so people can reach out to you. You can select your preferred way people can reach out to you.  You don't need to
                    select all of them.  One is fine if you think the people you talk to will reach out to you there.</p>

                <div className="alert alert-warning">
                    Note: The below information will be made public on the website.  Do not enter any information you don't want to be public. This is how people will contact you.
                </div>
                {phoneError && <div className="alert alert-danger">{phoneError}</div>}
                {/*
                <div className="form-group">
                    <label htmlFor="selectPhone" className="form-label">
                        <i className="bi bi-whatsapp"></i> Whatsapp
                    </label>
                    <input
                        id="selectPhone"
                        className={phoneError ?
                            "form-control from-control-lg is-invalid"
                            : "form-control form-control-lg"}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <small id="PhoneHelp" className="form-text text-muted">Talk directly with the tourists</small>
                    <div className="invalid-feedback">{phoneError}</div>
                </div>

                        */}


                <div className="form-group">
                    <label htmlFor="selectWebpage" className="form-label">

                        <i className="bi bi-globe" /> Personal website
                    </label>
                    <input
                        id="selectWebpage"
                        placeholder="https://torgyd.com"
                        className={websiteError ?
                            "form-control from-control-lg is-invalid"
                            : "form-control form-control-lg"}
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                    <small id="webpageHelp" className="form-text text-muted">Your own URLs are cool and help people get to know you.</small>
                    <div className="invalid-feedback">{websiteError}</div>
                </div>

                <div className="form-group">
                    <label htmlFor="selectFacebook" className="form-label">
                        <i className="bi bi-facebook" /> Facebook
                    </label>
                    <input
                        id="selectFacebook"
                        className={facebookError ?
                            "form-control from-control-lg is-invalid"
                            : "form-control form-control-lg"}
                        value={facebook}
                        placeholder="https://www.facebook.com/vallardx/about"
                        onChange={(e) => setFacebook(e.target.value)}
                    />
                    <small id="facebookHelp" className="form-text text-muted">Facebook is used worldwide even if its not as cool anymore among the youth in the US</small>
                    <div className="invalid-feedback">{facebookError}</div>
                </div>

                <div className="form-group">
                    <label htmlFor="selectInstagram" className="form-label">
                        <i className="bi bi-instagram" /> Instagram
                    </label>
                    <input
                        id="selectInstagram"
                        className={instagramError ?
                            "form-control from-control-lg is-invalid"
                            : "form-control form-control-lg"}
                        value={instagram}
                        placeholder="https://www.instagram.com/vallard"
                        onChange={(e) => setInstagram(e.target.value)}
                    />
                    <small id="instagramHelp" className="form-text text-muted">Instagram is still cool with people.  Recommended.</small>
                    <div className="invalid-feedback">{instagramError}</div>
                </div>

                <div className="form-group">
                    <label htmlFor="selectTwitter" className="form-label">
                        <i className="bi bi-twitter" /> Twitter /&nbsp;
                        <i className="bi bi-twitter-x" /> X

                    </label>
                    <input
                        id="selectTwitter"
                        placeholder="https://x.com/vallard"
                        className={twitterError ?
                            "form-control from-control-lg is-invalid"
                            : "form-control form-control-lg"}
                        value={twitter}
                        onChange={(e) => setTwitter(e.target.value)}
                    />
                    <small id="twitterHelp" className="form-text text-muted">Twitter/X is sort of mess right now, but people still like it.</small>
                    <div className="invalid-feedback">{twitterError}</div>
                </div>

                <div className="form-group">
                    <label htmlFor="selectLinkedin" className="form-label">
                        <i className="bi bi-linkedin" /> LinkedIn
                    </label>
                    <input
                        id="selectLinkedin"
                        placeholder="https://www.linkedin.com/in/vallard"
                        className={linkedinError ?
                            "form-control from-control-lg is-invalid"
                            : "form-control form-control-lg"}
                        value={linkedin}
                        onChange={(e) => setLinkedin(e.target.value)}
                    />
                    <small id="linkedinHelp" className="form-text text-muted">LinkedIn makes you legit with the biz crowd.</small>
                    <div className="invalid-feedback">{linkedinError}</div>
                </div>

                <div className="form-group">
                    <label htmlFor="tiktok" className="form-label">
                        <i className="bi bi-tiktok" /> TikTok
                    </label>
                    <input
                        id="tiktok"
                        className={tiktokError ?
                            "form-control from-control-lg is-invalid"
                            : "form-control form-control-lg"}
                        value={tiktok}
                        onChange={(e) => setTiktok(e.target.value)}
                    />
                    <small id="tiktokHelp" className="form-text text-muted">TikTok can make you cool with the younger crowd.</small>
                    <div className="invalid-feedback">{tiktokError}</div>
                </div>


                <div className="my-3 text-center">
                    <button type="submit" className="btn btn-lg btn-outline-dark">Submit</button>
                </div>
            </fieldset>
        </form >
    )
}

export default TourGuideForm;