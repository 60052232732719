import React from 'react';
import Layout from './layout';

const Privacy = () => {
    return (
        <Layout selected="privacy">
            <div className="container min-vh-100">
                <h1>Privacy</h1>
                <div>
                    The data you give me I do not share.
                    <br />
                    I do not take it anywhere.
                    <br />
                    I do not sell it in a box.
                    <br />
                    I do not sell it to a fox.
                    <br />
                    I do not track the clicks you make
                    <br />
                    I do not track the moves you take.
                    <br />
                    Facebook, Google and all their trackers
                    <br />
                    They can all go suck on crackers.
                    <br />
                    I do not track you here or there.
                    <br />
                    I do not track you anywhere.
                </div>
            </div>
        </Layout>
    );
}

export default Privacy;