import React from "react";
import { Outlet, Route, Routes, BrowserRouter } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import Home from '../components/home';
import NotFound from '../components/notfound';
import SignUp from '../components/auth/signup';
import SignOut from '../components/auth/signout';
import SignIn from '../components/auth/signin';
import GoogleCallback from "../components/auth/callbacks/google";
import ForgotPassword from "../components/auth/forgot";
import ResetPassword from "../components/auth/reset";
import EditTour from "../components/tourguide/tours/edit";
import TourGuideProfile from "../components/tourguide/edit";
import TourGuideHome from "../components/tourguide";
import TourGuideBrochure from "../components/tourguide/brochure";
import Tour from "../components/tour"
import About from "../components/about";
import Location from "../components/location";
import Safety from "../components/safety";
import Privacy from "../components/privacy";
import EatWithTheNatives from "../components/eatwiththenatives";
import { useSelector } from "react-redux";


const PrivateOutlet = () => {
    const authToken = useSelector(state => state.Auth.accessToken);
    if (authToken) {
        return <Outlet />;
    } else {
        return <Navigate to="/signin" />;
    }
}

const allRoutes = () =>
    <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/s/:location" element={<Location />} />
            <Route exact path="/tours/:tourId" element={<Tour />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/signin" element={<SignIn />} />
            <Route exact path="/signout" element={<SignOut />} />
            <Route exact path="/callback/google" element={<GoogleCallback />} />
            <Route exact path="/auth/forgot" element={<ForgotPassword />} />
            <Route exact path="/auth/reset" element={<ResetPassword />} />
            <Route exact path="/tourguide/about" element={<TourGuideBrochure />} />
            <Route exact path="/eatwiththenatives" element={<EatWithTheNatives />} />
            <Route exact path="/safety" element={<Safety />} />
            <Route exact path="/privacy" element={<Privacy />} />

            <Route exact path="/tourguide" element={<PrivateOutlet />}>
                <Route exact path="/tourguide" element={<TourGuideHome />} />
            </Route>
            <Route exact path="/tourguide/edit" element={<PrivateOutlet />}>
                <Route exact path="/tourguide/edit" element={<TourGuideProfile />} />
            </Route>
            <Route exact path="/tourguide/tours/create" element={<PrivateOutlet />}>
                <Route exact path="/tourguide/tours/create" element={<EditTour />} />
            </Route>
            <Route exact path="/tourguide/tours/:tourId" element={<PrivateOutlet />}>
                <Route exact path="/tourguide/tours/:tourId" element={<EditTour />} />
            </Route>

            {/* Default route doesn't exist */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
export default allRoutes