import React from "react";
import { useTranslation } from 'react-i18next';


const Footer = () => {
    const { t } = useTranslation()
    return (
        <footer className="py-4 my-md-5 py-md-5 footer border-top">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h4>TorGyd</h4>
                        <p>
                            {t('footer-tagline')}
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h4>Links</h4>
                        <ul className="list-unstyled">
                            <li>
                                <a href="/about">{t('footer-about')}</a>
                            </li>
                            <li>
                                <a href="/safety">{t('footer-safety')}</a>
                            </li>
                            <li>
                                <a href="/privacy">{t('footer-privacy')}</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h4>{t('footer-socials')}</h4>
                        <a href={`https://twitter.com/vallard`}
                            rel="noopener noreferrer"
                            target="_blank">
                            <i className="bi-twitter"></i>
                        </a>
                        &nbsp;
                        <a href={`https://www.linkedin.com/in/vallard/`}
                            rel="noopener noreferrer"
                            target="_blank">
                            <i className="bi-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div>
        </footer >
    );
}

export default Footer;