import React, { useState } from 'react';
import { Link } from "react-router-dom";

function NotFound() {
    // Declare a new state variable, which we'll call "count"  const [count, setCount] = useState(0);
    const [count, setCount] = useState(0);
    return (
        <div className="container">
            <p className="lead">
                The page you are looking for does not exist.  Perhaps you'd like to play with this clicker button?
            </p>
            <div>
                <button className="btn btn-outline-dark" onClick={() => setCount(count + 1)}>
                    Click me
                </button>
            </div>
            <p className="lead text-">You clicked {count} times</p>
            <hr />
            <div className="lead">Or, you can always go <Link to="/">Home</Link></div>
        </div>
    );
}
export default NotFound;