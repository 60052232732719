import React from 'react';
import { Link } from 'react-router-dom';
import DisplayTour from '../displayTour';
import DisplayTourGuide from '../displayTourGuide';

const Profile = ({ tourGuideProfile, locations }) => {
    return (
        <div className="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div className="col-md-10">
                <DisplayTourGuide tourguide={tourGuideProfile} locations={locations} />

            </div>
            <div className="col-md-2 d-flex justify-content-end">
                <Link to="/tourguide/edit" className="btn btn-outline-dark">Edit <i className="bi bi-pencil-square"></i></Link>
            </div>
        </div>
    )
}

const MySchedule = () => {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Schedule</h1>
                <Link to="/tourguide/tourEvents/edit" className="btn btn-outline-dark">Edit <i className="bi bi-calendar-plus"></i></Link>
            </div>
        </>
    )
}

const MyTours = ({ tours }) => {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>My Tours</h1>
                <Link to="/tourguide/tours/create" className="btn btn-outline-dark">Create Tour <i className="bi bi-plus-circle"></i></Link>
            </div>
            {tours && tours.length > 0 ?

                tours.map((tour) => (
                    <Link to={"/tourguide/tours/" + tour.id} key={tour.id}>
                        <DisplayTour tour={tour} />
                    </Link>
                ))
                :
                <p>You don't have any tours yet.  Create your first tour to show people around!</p>
            }
        </>
    )
}


const ProfileView = ({ tourGuideProfile, activeTab, handleTabClick, tours, locations }) => {
    return (
        <>
            <ul className='nav nav-tabs'>
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}
                        onClick={() => handleTabClick('tab1')}
                        href="#tab1"
                    >
                        My Schedule
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`}
                        onClick={() => handleTabClick('tab2')}
                        href="#tab2"
                    >
                        My Tours
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`}
                        onClick={() => handleTabClick('tab3')}
                        href="#tab3"
                    >
                        Profile
                    </a>
                </li>
            </ul>
            <div className="mt-3">
                {activeTab === 'tab1' && <MySchedule />}
                {activeTab === 'tab2' && <MyTours tours={tours} />}
                {activeTab === 'tab3' && <Profile tourGuideProfile={tourGuideProfile} locations={locations} />}
            </div>
        </>
    )
}

export default ProfileView;