import React from 'react'

const ShowSocials = ({ tourguide }) => {
    return (
        <div className="lead">
            {tourguide.webpage &&
                <a href={tourguide.webpage}>
                    <i className="bi-globe" />
                </a>

            }
            {tourguide.facebook &&
                <a href={tourguide.facebook}>
                    <i className="bi bi-facebook mx-1" />
                </a>
            }
            {tourguide.instagram &&
                <a href={tourguide.instagram}>
                    <i className="bi bi-instagram mx-1" />
                </a>
            }
            {tourguide.twitter &&
                <a href={tourguide.twitter}>
                    <i className="bi bi-twitter mx-1" />
                </a>
            }
            {tourguide.tiktok &&
                <a href={tourguide.tiktok}>
                    <i className="bi bi-tiktok mx-1" />
                </a>
            }
            {tourguide.linkedin &&
                <a href={tourguide.linkedin}>
                    <i className="bi bi-linkedin mx-1" />
                </a>
            }

        </div>
    )
}




const TourGuideRow = ({ tourguide }) => {
    return (
        <div className="row">
            <div className="col-md-2">
                {tourguide.avatar_url &&
                    <img src={tourguide.avatar_url} className="d-block img-fluid rounded-start rounded-end"
                        style={{ maxHeight: '100px', objectFit: 'contain' }}
                        alt={tourguide.name} />
                }
            </div>
            <div className="col-md-10">
                <h6>{tourguide.name}</h6>
                <p>{tourguide.bio}</p>
                {tourguide.eat_with_local_preferences &&
                    <div>
                        <b>Buy me</b>
                        {tourguide.eat_with_local_preferences.split(",").map((preference, index) => (
                            <span className={`mx-1 badge rounded-pill badge-${preference}`} key={index}>
                                {preference}
                            </span>
                        ))}
                    </div>
                }
                <b>Contact to meet this person via the below social media channels</b>
                <ShowSocials tourguide={tourguide} />
            </div>
        </div>
    )
}




const DisplayTourGuide = ({ tourguide, locations, compact }) => {
    return (
        <>
            {compact ?
                <TourGuideRow tourguide={tourguide} />
                :
                <div>
                    {tourguide.avatar_url &&
                        <div className="image-wrapper my-2" style={{ textAlign: 'left' }}>
                            <img src={tourguide.avatar_url} className="img-fluid rounded-start"
                                style={{ maxHeight: '200px', objectFit: 'scale-down' }}
                                alt={tourguide.name} />
                        </div>
                    }
                    <h5>{tourguide.name}</h5>
                    {locations && locations.length > 0 && locations.filter(location => location.id === tourguide?.location_id).map((loc) => {
                        return (
                            <div className="text-secondary" key={loc.id}>
                                {loc.state ? <>{loc.city}, {loc.state}, {loc.country}</>
                                    : <>{loc.city}, {loc.country}</>
                                }
                            </div>
                        )
                    })}
                    <div>
                        {tourguide?.languages && tourguide.languages.split(',').length > 0 && tourguide.languages.split(',').map((lang) => {
                            return (
                                <span className="badge bg-secondary me-1" key={lang}>{lang}</span>
                            )
                        })}
                    </div>
                    <p className="mt-2">{tourguide.bio}</p>

                    People will contact me via the below social media channels:
                    <ShowSocials tourguide={tourguide} />
                </div>
            }
        </>
    )
}

export default DisplayTourGuide