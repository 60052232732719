import React from 'react';
import Layout from './layout';
import Emoji from './emoji';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <Layout selected="about">
            <div className="container min-vh-100">
                <p>
                    <Emoji emoji="👋" label="waving hand" /> Hi! My name is Vallard and I live in the Portland, Oregon area. I'm happily married and have 4 amazing kids.
                </p>
                <img src="https://s3.us-west-2.amazonaws.com/photos.torgyd.com/vallard-300.png" alt="Vallard" className="img-fluid" />
                <p className="mt-2">
                    I took my first solo trip when I was 18 to Isreal and lived on a kibbutz for several months.
                    It was the first time I ventured out. Like you, I love to travel.
                    I've realized the part I love
                    the most about it is making connections with other people. I want to make a platform to enhance the experience of travelers and the people who live in the places they visit.
                </p>
                <h4>Connect globally by connecting locally.</h4>
                <p>There are two social issues I hope to solve with this platform.</p>
                <ul>
                    <li><b>Loneliness</b> The World Health Organization (WHO) stated "that one in four people experience social isolation, and one in six adolescents is socially
                        isolated and lonley, though this is likely an underestimation."
                        (<a href="https://www.who.int/director-general/speeches/detail/who-director-general-s-opening-remarks-at-the-media-briefing-15-november-2023">Source</a>)
                        Our research has uncovered a growing trend of solo travelers lately with the majority of this trend being females.
                        We believe that connections with others is the key to happiness and we want to help people make those connections.
                        The WHO also stated that "Social connection has profound benefits for improving health, education, and the economy."
                        <br />
                        <br />
                        <p>I recently read <a href="https://the-good-life-book.com/">The Good Life</a> by Robert Waldinger and Marc Schulz.
                            In this book they talk about the Harvard Study of Adult Development which is the longest running study of adult life that's ever been done.
                            They found that the people who were the happiest and healthiest were the people who had the strongest relationships. This site is to help make more connections.
                        </p>
                    </li>
                    <br />
                    <li><b>Empower emerging entreprenuers</b> We have seen how large corporations like AirBnb or Uber have
                        taken advantage of people all over the world by taking a large percentage of their earnings. These
                        companies require an initial outlay of capital that is becomeing more difficult for most people to obtain.  Houses and cars
                        cost considerable amount of money. With a tour based business there are very little barriers to entry.
                        Anybody with some grit and hustle culture can have the flexibility to run their own business.
                        In this way I want to bring economic freedom to people all over the world. I also think that a local can give a better experience than a large corporation.
                    </li>
                </ul>

                <p className="mt-2">
                    As a lifelong traveler and a person who has lived in many different places I have made real lasting connections with people from all
                    over the world even if our contact was simply brief.
                    Travel helps eliminate fear, prejudice, and hate. It is the best education a person can have to stop wars and bring more
                    peace to the world.
                </p>
                <h3 className="mt-2">How torgyd makes money</h3>
                <p>So first of all this site makes no money at all.  There's nothing on it right now. I'm working on it, so let me share some of my plans.</p>
                <ul>
                    <li>tour guides set their own prices, show their own websites, venmos, etc, and do not have to use any of our system. In this use case
                        I do not charge any fees and probably never will. This is an option for more experienced tour operators who already have systems set up.
                        So from this perspective, I do not make any money, and I am okay with that.
                    </li>
                    <li>
                        <Link to="/eatwiththenatives">Eat with the Natives</Link> is a free experimental service I think is cool that I wanted to try out.
                    </li>
                </ul>
                <p>Ok, So how would you make money to keep this site going?</p>
                <ul>
                    <li>The first way I make money is by offering a tour.  So I'm in the portland area and will offer a tour there. If you are in town, hit me up! This is up now!</li>
                    <li>The second way I could make money is for tour guides to use a system I build to manage their tours.
                        In this case, I pay 3% to the credit card transaction vendor that we use and take an additional 2% for each booking.
                        This is a great use case for smaller up and coming tour guides who do not have a system set up yet. So whatever the price
                        the tourguide sets, I take 5%. There are no other hidden fees or taxes or charges.
                    </li>
                    <li>
                        The third way I will make money is for tour operators to advertise their tours on our systems when people are looking for a tour in their area.
                        We will charge a small fee for this service. But this isn't even implemented yet and I don't know if I'll ever have any time to do it.
                    </li>

                </ul>
            </div>
        </Layout >
    );
}

export default About;