import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authRequest } from '../../redux/auth/actions';
import { useNavigate, Link } from 'react-router-dom';
import SignUpButton from './signupbutton';
import Layout from '../layout';
import { useTranslation } from 'react-i18next';

function SignIn() {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const navigate = useNavigate();
	const accessToken = useSelector(state => state.Auth.accessToken);

	const signInMethod = (params) => {
		dispatch(authRequest(params));
	}

	useEffect(() => {
		if (accessToken) {
			navigate('/');
		}
	}, [accessToken, navigate]);

	return (
		<Layout selected="signin">
			<div className="my-5">
				<h1>{t('signin')}</h1>
				<SignUpButton requestFunc={signInMethod} buttonType={t('signin')} />
				<div className="mt-3 text-center">
					<Link to="/auth/forgot">{t('signin-forgot-password')}</Link> |
					{t('signin-no-account')} <Link to="/signup">{t('signup')}</Link>
				</div>
			</div>
		</Layout>
	)
}

export default SignIn;