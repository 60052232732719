import React from "react";
import { useSelector } from 'react-redux';

const messages = {
    "PASSWORD_RESET": "If you have entered a valid email, password reset instructions have been sent to your email!  Please check your email and follow the instructions.",
    "PASSWORD_RESET_SUCCESS": "Your password has been reset!  Please sign in with your new password.",
}

function InfoMessage() {
    const message = useSelector(state => state.Auth.message);
    const apiMessage = useSelector(state => state.API.message);
    return (
        <>
            {message &&
                <div className="alert alert-success" role="alert">
                    {message && messages[message] ? messages[message] : message.toString()}
                </div>
            }
            {apiMessage &&
                <div className="alert alert-success" role="alert">
                    {apiMessage && messages[apiMessage] ? messages[apiMessage] : apiMessage.toString()}
                </div>
            }
        </>
    );
}

export default InfoMessage;
