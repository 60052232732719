import React, { useEffect, useState } from "react";
import Layout from "./layout";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiError } from "../redux/api/actions";
import { doFetch } from "../redux/api/actions";
import { GET_LOCATION_VIEW } from "../redux/api/constants";
import { Link } from "react-router-dom";
import { displayCostType } from "./utils";
import DisplayTourGuide from "./displayTourGuide";
import { useTranslation } from "react-i18next";


const ShowDineWithLocal = ({ tourguide }) => {
    return (
        <DisplayTourGuide tourguide={tourguide} compact="true" />
    )
}

const ShowTour = ({ tour }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const updateActiveIndex = (increment) => {
        const imageUrls = tour.image_urls.split(",");
        const maxIndex = imageUrls.length - 1;
        let newIndex = activeIndex + increment;

        if (newIndex < 0) {
            newIndex = maxIndex; // Go to the last image if we've reached the beginning
        } else if (newIndex > maxIndex) {
            newIndex = 0; // Go to the first image if we've reached the end
        }

        setActiveIndex(newIndex);
    };


    return (
        <div className="col-lg-4 col-md-6" key={tour.id}>
            <a href={`/tours/${tour.id}`} key={tour.id} className="tour-link" target="_blank" rel="noopener noreferrer">
                <div className="card mb-3" >
                    {tour.image_urls &&
                        <div id="imageCarousel" className="carousel slide" data-bs-ride="carousel">
                            {
                                tour.image_urls.split(",").map((imageUrl, index) => (
                                    <div className="carousel-inner" key={index}>
                                        <div className={index === activeIndex ? "carousel-item active" : "carousel-item"}>
                                            <img src={imageUrl} className="d-block w-100 img-fluid rounded-start card-img-top"
                                                alt={imageUrl} />
                                        </div>
                                    </div>
                                ))}
                            <button className="carousel-control-prev" type="button" data-bs-target="#imageCarousel" data-bs-slide="prev" onClick={() => updateActiveIndex(-1)}>
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#imageCarousel" data-bs-slide="next" onClick={() => updateActiveIndex(1)}>
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    }
                    <div className="card-body">
                        <div className={`badge rounded-pill badge-flavor-${tour.flavor.toLowerCase()}`}>
                            {tour.flavor}
                        </div>&nbsp;
                        <div className={`badge rounded-pill badge-adventure-${tour.adventure_size.replace('_', '').toLowerCase()}`}>
                            {tour.adventure_size}
                        </div>

                        <h5 className="card-title mt-2">{tour.title}</h5>
                        <div className="card-text d-flex justify-content-between">
                            <span className="text-muted">
                                ${tour.cost}/ {displayCostType(tour.cost_type)}
                            </span>
                            <span>
                                {tour.duration_in_hours} hours
                            </span>
                        </div>

                    </div>
                </div>
            </a>
        </div>
    )
}



const Location = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const locationView = useSelector(state => state.API.locationView);
    const loading = useSelector(state => state.API.loading);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const locationParamToText = (location) => {
        const locationArray = location.split("--");
        var city = locationArray[0].replace(/-/g, " ");
        var country = ""
        var state = ""
        if (locationArray.length === 2) {
            country = locationArray[1].replace(/-/g, " ");
        } else if (locationArray.length === 3) {
            state = locationArray[1] ? locationArray[1].replace(/-/g, " ") : "";
            country = locationArray[2].replace(/-/g, " ");

        } else {
            dispatch(apiError(`${location} is an invalid location`))
            city = "";
        }
        const returnVal = `${city}${state ? `, ${state}` : ""}${country ? `, ${country}` : ""}`;
        return returnVal;
    };

    const { location } = useParams();
    const locationText = locationParamToText(location);

    useEffect(() => {

        const locationArray = location.split("--");
        const city = locationArray[0].replace(/-/g, " ");
        var country = ""
        var state = ""
        if (locationArray.length === 2) {
            country = locationArray[1].replace(/-/g, " ");
        } else if (locationArray.length === 3) {
            state = locationArray[1] ? locationArray[1].replace(/-/g, " ") : "";
            country = locationArray[2].replace(/-/g, " ");

        } else {
            return
        }

        if (locationText) {
            dispatch(
                doFetch(
                    GET_LOCATION_VIEW,
                    `locations/view?city=${city}${state ? `&state=${state}` : ""
                    }${country ? `&country=${country}` : ""}`
                )
            );
        }
    }, [dispatch, locationText, location]);


    return (
        <Layout selected="location">
            {locationView && locationView.length > 0 ?
                locationView.map((item, index) => (
                    <div key={index}>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item" aria-current="page">
                                    {item.city && `${item.city}`}
                                    {item.state && `, ${item.state}`}
                                    {item.country && ` ${item.flag}`}

                                </li>
                            </ol>
                        </nav>
                        <div className="row g-0">
                            <div className="col-md-12" style={{ height: '50px' }}>
                                <div className="card ">
                                    <div
                                        className="card-img-overlay dark-overlay d-flex flex-column justify-content-center"
                                        style={{
                                            backgroundImage: `url(${item.image_url})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            height: '50px' // Adjust this value as needed
                                        }}
                                    >
                                        <h5 className="card-title text-white" style={{ fontSize: '1.5rem' }}>
                                            {item.city && `${item.city}`}
                                            {item.state && `, ${item.state}`}
                                            {item.country && ` ${item.flag}`}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ul className="nav nav-tabs mt-3" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className={activeIndex === 0 ? "nav-link active" : "nav-link"}
                                        id="dineWithLocals-tab"
                                        data-toggle="tab"
                                        href="#dineWithLocals"
                                        role="tab"
                                        aria-controls="dineWithLocals"
                                        aria-selected={activeIndex === 0 ? "true" : "false"}
                                        onClick={() => setActiveIndex(0)}
                                    >
                                        {t('ewtn-title')}
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className={activeIndex === 1 ? "nav-link active" : "nav-link"}
                                        id="experiences-tab"
                                        data-toggle="tab"
                                        href="#experiences"
                                        role="tab"
                                        aria-controls="experiences"
                                        aria-selected={activeIndex === 1 ? "true" : "false"}
                                        onClick={e => setActiveIndex(1)}
                                    >
                                        {t('location-experiences-guided-tours')}
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className={activeIndex === 0 ? "tab-pane show active" : "tab-pane "}
                                    id="dineWithLocals"
                                    role="tabpanel"
                                    aria-labelledby="dineWithLocals-tab">
                                    <div className="col-md-12 mt-3">
                                        <p>{t('location-ewtn')} <Link to="/eatwiththenatives">{t('rules')}</Link>.
                                        </p>
                                        <hr />
                                        {item.tourguides && item.tourguides.filter(guide => guide.eat_with_local === true).length > 0 ?
                                            item.tourguides.filter(guide => guide.eat_with_local === true).map((tourguide, index) => (
                                                <ShowDineWithLocal tourguide={tourguide} key={index} />
                                            ))
                                            :
                                            <>
                                                {!loading &&
                                                    <div className="alert alert-info" role="alert">
                                                        Sorry, we could not find any locals participating yet for {locationText}.
                                                        <br />
                                                        Do you live here? <Link to="/signup">{t('signup')}</Link> to be a local host.
                                                    </div>}
                                            </>

                                        }
                                    </div>
                                </div>
                                <div className={activeIndex === 1 ? "tab-pane show active" : "tab-pane "}
                                    id="experiences"
                                    role="tabpanel"
                                    aria-labelledby="experiences-tab">
                                    <div className="col-md-12 mt-3">
                                        {item.tours && item.tours.length > 0 ?
                                            item.tours.map((tour, index) => (
                                                <ShowTour tour={tour} key={index} />
                                            ))
                                            :
                                            <>
                                                {!loading &&
                                                    <div className="alert alert-info" role="alert">
                                                        {t('location-no-experiences')}
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ))
                : <>{!loading && <div className="alert alert-danger" role="alert">
                    {t('location-no-information')} {locationText}.
                </div>}
                </>
            }
        </Layout >
    );
}

export default Location;