import axios from 'axios';
import config from '../../config'


const getHeaders = (accessToken) => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
    };
};


export const registerNewUser = async (userData) => {
    try {
        const response = await axios.post(`${config.API_ENDPOINT}/auth/register`, userData);
        return response.data;
    } catch (error) {
        // any errors that could happen should be made into a string here and thrown. 
        console.warn(error)
        if (error.response?.data?.detail) {
            throw error.response.data.detail
        } else {
            throw error;
        }
    }
}

export const loginRequest = async (credentials) => {
    try {
        const formData = new FormData();
        formData.append('username', credentials.email);
        formData.append('password', credentials.password);

        const response = await axios.post(`${config.API_ENDPOINT}/auth/jwt/login`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.warn(error)
        if (error.response?.data?.detail) {
            throw error.response.data.detail
        } else {
            throw error;
        }
    }
}

export const logoutRequest = async () => {
    try {
        const accessToken = sessionStorage.getItem('accessToken');
        const headers = getHeaders(accessToken);
        await axios.post(
            `${config.API_ENDPOINT}/auth/jwt/logout`,
            null,
            { headers: headers }
        );
    } catch (error) {
        throw error;
    }
}


export const forgotPassword = async (email) => {
    try {
        await axios.post(`${config.API_ENDPOINT}/auth/forgot-password`, { email });
    } catch (error) {
        throw error;
    }
}

export const sendVerificationCode = async (email) => {
    try {
        await axios.post(`${config.API_ENDPOINT}/send-verification-code`, { email });
    } catch (error) {
        throw error;
    }
}

/* Google OAuth stuff */
export const getGoogleOAuthUrl = async () => {
    try {
        const response = await axios.get(`${config.API_ENDPOINT}/auth/google/authorize`);
        return response.data.authorization_url
    } catch (error) {
        if (error.response?.data?.detail) {
            throw error.response.data.detail
        } else {
            throw error;
        }
    }
}

export const sendGoogleOAuthCallback = async ({ code, state, scope }) => {
    try {
        const response = await axios.get(`${config.API_ENDPOINT}/auth/google/callback?code=${code}&state=${state}&scope=${scope}`);
        return response.data
    } catch (error) {
        console.warn(error)
        if (error.response?.data?.detail) {
            throw error.response.data.detail
        } else {
            throw error;
        }
    }
}

export const sendResetPassword = async ({ token, password }) => {
    try {
        const response = await axios.post(`${config.API_ENDPOINT}/auth/reset-password`,
            { "token": token, "password": password },
            { headers: { "Content-Type": "application/json" } });
        return response.data
    } catch (error) {
        console.warn(error)
        if (error.response?.data?.detail) {
            throw error.response.data.detail
        } else {
            throw error;
        }
    }
}