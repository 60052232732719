import React from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const errorMap = {
    REGISTER_USER_ALREADY_EXISTS: (
        <span>
            So sorry! This user already exists, please use a different email address or <Link to="/signin">sign in!</Link>
        </span>
    ),
    RESET_PASSWORD_BAD_TOKEN: "Sorry, this password reset token is invalid.  Please try to reset your password again.",
    LOGIN_BAD_CREDENTIALS: (
        <span>
            Sorry, your email or password is incorrect.  Please try again.
            If you forgot your password, you can <Link to="/auth/forgot">reset your password here.</Link>
        </span>
    ),
}

function ErrorMessage() {
    const error = useSelector(state => state.Auth.error);
    const apiError = useSelector(state => state.API.error);
    return (
        <>
            {
                error &&
                <div className="alert alert-danger" role="alert">
                    {errorMap[error] || error.toString()}
                </div>
            }
            {
                apiError &&
                <div className="alert alert-danger" role="alert">
                    {errorMap[apiError] || apiError.toString()}
                </div>
            }
        </>
    );
}

export default ErrorMessage;
