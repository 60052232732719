import React from 'react'
import { displayCostType } from './utils'

const DisplayTour = ({ tour }) => {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const updateActiveIndex = (increment) => {
        const imageUrls = tour.image_urls.split(",");
        const maxIndex = imageUrls.length - 1;
        let newIndex = activeIndex + increment;

        if (newIndex < 0) {
            newIndex = maxIndex; // Go to the last image if we've reached the beginning
        } else if (newIndex > maxIndex) {
            newIndex = 0; // Go to the first image if we've reached the end
        }

        setActiveIndex(newIndex);
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    {tour.image_urls &&
                        <div id="imageCarousel" className="carousel slide" data-bs-ride="carousel">
                            {
                                tour.image_urls.split(",").map((imageUrl, index) => (
                                    <div className="carousel-inner carousel-background" key={index}>
                                        <div className={index === activeIndex ? "carousel-item active" : "carousel-item"}>
                                            <img src={imageUrl} className="d-block w-100 img-fluid rounded-start card-img-top"
                                                style={{ maxHeight: '400px', objectFit: 'contain' }}
                                                alt={imageUrl} />
                                        </div>
                                    </div>
                                ))}
                            <button className="carousel-control-prev" type="button" data-bs-target="#imageCarousel" data-bs-slide="prev" onClick={() => updateActiveIndex(-1)}>
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#imageCarousel" data-bs-slide="next" onClick={() => updateActiveIndex(1)}>
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div className="col-md-12">
                <h1>{tour.title}</h1>
                <p className="text-muted">{tour.description}</p>
                <div className={`badge rounded-pill badge-flavor-${tour.flavor.toLowerCase()}`}>
                    {tour.flavor}
                </div>&nbsp;
                <div className={`badge rounded-pill badge-adventure-${tour.adventure_size.replace('_', '').toLowerCase()}`}>
                    {tour.adventure_size}
                </div>

                <h5 className="card-title mt-2">{tour.title}</h5>
                <div className="card-text d-flex justify-content-between">
                    <span className="text-muted">
                        ${tour.cost}/ {displayCostType(tour.cost_type)}
                    </span>
                    <span>
                        {tour.duration_in_hours} hours
                    </span>
                </div>
                <hr />

            </div>
            {tour.tourguide &&
                <div className="col-md-8">
                    <h6>Hosted by {tour.tourguide.name}</h6>
                    {tour.tourguide.email &&
                        <div><b>Email </b>
                            <a href={`mailto:${tour.tourguide.email}`}>{tour.tourguide.email}</a>
                        </div>
                    }
                    {tour.tourguide.phone &&
                        <div className="my-2"><b>Phone </b>
                            <a href={`tel:${tour.tourguide.phone}`}>{tour.tourguide.phone}</a>
                        </div>
                    }
                    {tour.tourguide.facebook &&
                        <div className="my-2"><b>Facebook </b>
                            <a href={`tel:${tour.tourguide.facebook}`}>{tour.tourguide.facebook}</a>
                        </div>
                    }
                    {tour.tourguide.webpage &&
                        <div className="my-2"><b>Website</b>
                            <a href={`tel:${tour.tourguide.webpage}`}>{tour.tourguide.webpage}</a>
                        </div>
                    }
                    {tour.tourguide.tiktok &&
                        <div className="my-2"><b>TikTok</b>
                            <a href={`tel:${tour.tourguide.tiktok}`}>{tour.tourguide.tiktok}</a>
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default DisplayTour