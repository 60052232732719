import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { doFetch } from '../redux/api/actions';
import { GET_LOCATIONS } from '../redux/api/constants';
import Layout from './layout';
import Search from './search';
import { useTranslation } from 'react-i18next';

const Hero = () => {
    const me = useSelector(state => state.API.me)
    const { t } = useTranslation();
    return (
        <>

            <div className="jumbotron px-5">
                <p className="display-4">{t('home-hero-title')}</p>
                <p className="lead">{t('home-hero-subtitle')}</p>
                <hr className="my-4" />
                <div className="text-center">
                    {me ?
                        <Link to="/tourguide" className="btn btn-outline-dark btn-lg">{t('update-profile')}</Link>
                        :
                        <>
                            <p>{t('home-signup-ad')}</p>
                            <p >
                                <Link to="/signup" className="btn btn-outline-dark btn-lg">{t('signup')}</Link>
                            </p>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

function Home() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(doFetch(GET_LOCATIONS, "locations"));
    }, [dispatch])

    const allDestinations = useSelector(state => state.API.locations);
    return (
        <Layout selected="home" nocontainer="true">
            <Search destinations={allDestinations} jumbo={true} />
            <div className="container min-vh-100 mt-3 text-center">
                <Hero />
            </div>
        </Layout>
    );
}
export default Home;