import api_endpoint from '../../settings'


const getHeaders = (accessToken) => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
    };
};

const api = {
    get(data) {
        var url = api_endpoint + "/" + data.path
        return fetch(url, {
            method: 'GET',
            headers: getHeaders(data.access_token)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorData => {
                        throw new Error(`Request failed with status ${response.status}: ${response.statusText}. Details: ${JSON.stringify(errorData)}`);
                    });
                }
                return response.json()
            });
    },
    create(data) {
        /* data.path = register, etc. 
           data.body = json object with key value: {"foo", "bar"}
        */
        var url = api_endpoint + "/" + data.path
        return fetch(url, {
            method: 'POST',
            headers: getHeaders(data.access_token),
            body: JSON.stringify(data.data)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorData => {
                        throw new Error(`Request failed with status ${response.status}: ${response.statusText}. Details: ${JSON.stringify(errorData)}`);
                    });
                }
                return response.json();
            });
    },
    put(data) {
        var url = api_endpoint + "/" + data.path
        return fetch(url, {
            method: 'PUT',
            headers: getHeaders(data.access_token),
            body: JSON.stringify(data.data)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorData => {
                        throw new Error(`Request failed with status ${response.status}: ${response.statusText}. Details: ${JSON.stringify(errorData)}`);
                    });
                }
                return response.json();
            });

    },
    delete(data) {
        var url = api_endpoint + "/" + data.path
        return fetch(url, {
            method: 'DELETE',
            headers: getHeaders(data.access_token),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorData => {
                        throw new Error(`Request failed with status ${response.status}: ${response.statusText}. Details: ${JSON.stringify(errorData)}`);
                    });
                }
                return response.json();
            });
    },
    s3Upload(data) {
        const uploadPromises = [];
        const urls = data.urls;
        const files = data.files;

        // Iterate through both arrays simultaneously
        urls.forEach((url, index) => {
            const file = files[index]; // Get the file data for the current file

            const uploadPromise = fetch(url, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        // console.log(response);
                    }
                    return response;
                });

            uploadPromises.push(uploadPromise);
        });

        return Promise.all(uploadPromises);
    }
}

export default api;