import React, { useEffect } from 'react';
import SignUpButton from './signupbutton';
import { useDispatch, useSelector } from 'react-redux';
import { registerRequest, oauthSignupGoogleRequest } from '../../redux/auth/actions';
import { useNavigate, Link } from 'react-router-dom';
import Layout from '../layout';
import { useTranslation } from 'react-i18next';

function SignUp() {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const navigate = useNavigate();
	const accessToken = useSelector(state => state.Auth.accessToken);

	// Use a useEffect to handle navigation when userData changes
	useEffect(() => {
		if (accessToken) {
			navigate('/signin');
		}
	}, [accessToken, navigate]);

	const signUpMethod = (params) => {
		dispatch(registerRequest(params));
	}

	const oauthSignUpMethod = (params) => {
		dispatch(oauthSignupGoogleRequest(params));
	}

	return (
		<Layout selected="signup">
			<div className="my-5">
				<h1>{t('signup')}</h1>
				<SignUpButton requestFunc={signUpMethod} oauthFunc={oauthSignUpMethod} buttonType={t('signup')} />
				<div className="mt-3 text-center">
					{t('signin-already-have-account')} <Link to="/signin">{t('signin')}</Link>
				</div>
			</div>
		</Layout>
	)
}

export default SignUp;