import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { doFetch, clearMe } from '../redux/api/actions';
import { logout } from '../redux/auth/actions';
import { ME } from '../redux/api/constants';
import Error from './auth/error';
import Info from './auth/info';
import Spinner from './auth/spinner';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../redux/site/actions';

function UserDropDown({ user }) {
	const dispatch = useDispatch();
	const signout = (e) => {
		e.preventDefault();
		dispatch(logout());
	}
	return (
		<li className="nav-item dropdown">
			<button className="btn btn-outline-dark nav-link dropdown-toggle" id="userDropDownMenu"
				data-bs-toggle="dropdown" aria-expanded="false" aria-label="Toggle navigation">
				{user && user.email}
			</button>
			<ul className="dropdown-menu" aria-labelledby="userDropDownMenu">
				<li><Link to="/tourguide" className="dropdown-item">TourGuide Profile</Link></li>
				<li><Link to="/signout" className="dropdown-item" onClick={signout}>Logout</Link></li>
			</ul>
		</li>
	)
}

const getFlagEmoji = (languageCode) => {
	switch (languageCode) {
		case 'en':
			return '🇬🇧'; // UK flag for English
		case 'it':
			return '🇮🇹'; // Italy flag for Italian
		case 'es':
			return '🇪🇸'; // Spain flag for Spanish
		default:
			return '🌐'; // Default Globe for other languages
	}
};

function LanguageDropDown() {
	const dispatch = useDispatch()
	const language = useSelector(state => state.Site.language);
	const { i18n } = useTranslation();
	const setLanguage = (language) => {
		dispatch(changeLanguage(language));
	};

	useEffect(() => {
		if (language) {
			i18n.changeLanguage(language);
		}
	}, [language, i18n]);

	return (
		<li className="nav-item dropdown mx-1">
			<button className="btn nav-link dropdown-toggle" id="languageDropdown"
				data-bs-toggle="dropdown" aria-expanded="false" aria-label="Toggle navigation">
				{getFlagEmoji(i18n.language)}
			</button>
			<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="languageDropdown">
				<li className="dropdown-item" onClick={() => setLanguage('en')}>🇬🇧</li>
				<li className="dropdown-item" onClick={() => setLanguage('it')}>🇮🇹</li>
				<li className="dropdown-item" onClick={() => setLanguage('es')}>🇪🇸</li>
			</ul>
		</li>
	)

}


function NavBar({ selected }) {
	const dispatch = useDispatch()
	const accessToken = useSelector(state => state.Auth.accessToken);
	const me = useSelector(state => state.API.me);
	const loading = useSelector(state => state.API.loading);
	const { t } = useTranslation();


	useEffect(() => {
		if (accessToken) {
			dispatch(doFetch(
				ME,
				'users/me',
				accessToken
			))
		} else {
			dispatch(clearMe())
		}

	}, [dispatch, accessToken]);



	return (
		<>
			<nav className="navbar navbar-light navbar-expand-md navbar-custom">
				<div className="container-fluid">
					<a className="navbar-brand" href="/">
						torgyd
					</a>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarToggler">
						<ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex">
							<li>
								<Link to="/eatwiththenatives" className={selected === "natives" ? "nav-link active" : "nav-link"}>{t('ewtn-title')}</Link>
							</li>
							{selected !== "tourguide" &&
								<li>
									<Link to="/tourguide/about" className={selected === "tourgide" ? "nav-link active" : "nav-link"}>
										{t('become-tourguide')}
									</Link>
								</li>

							}
							{loading ? <li className="nav-item">
								<	div className="spinner-border text-primary" role="status"></div>
							</li> :
								accessToken && me && Object.entries(me).length > 0 ?
									<>
										<UserDropDown user={me} />
									</>
									:
									<>
										<li className="nav-item">
											<Link to="/signin" className={selected === "signin" ? "nav-link active" : "nav-link"}>{t('signin')}</Link>
										</li>
										<li className="nav-item">
											<Link to="/signup" className={selected === "signup" ? "btn btn-outline-dark active" : "btn btn-outline-dark"}>{t('signup')}</Link>
										</li>
									</>


							}
							<LanguageDropDown />
						</ul>
					</div>


				</div>
			</nav >
			<Error />
			<Info />
			<Spinner />
		</>
	);
}

export default NavBar;