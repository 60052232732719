import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPasswordRequest, authFailure } from '../../redux/auth/actions';
import ErrorMessage from './error';
import Spinner from './spinner';
import InfoMessage from './info';
import { useNavigate } from 'react-router-dom';
import Layout from '../layout';

const ResetPassword = () => {
    const [newpassword, setNewPassword] = useState("");
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const search = useLocation().search;
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        var pEmail = new URLSearchParams(search).get('to');
        pEmail = decodeURIComponent(pEmail);
        const pToken = new URLSearchParams(search).get('code')

        if (pEmail && pToken) {
            setEmail(pEmail);
            setToken(pToken);
        }
    }, [search])

    const resetPassword = (e) => {
        e.preventDefault();
        if (newpassword === "") {
            dispatch(authFailure("Password can not be empty."));
            return;
        }
        dispatch(resetPasswordRequest({ password: newpassword, token: token, navigate: navigate }));
    }

    return (
        <Layout selected="reset">
            <div className="mb-3 col-md-4 offset-md-4 text-center mt-5">
                <InfoMessage />
                <ErrorMessage />
                <Spinner />
                <p className="lead">Enter New Password for <b>{email}</b></p>
                <form onSubmit={resetPassword}>
                    <fieldset>
                        <div className="form-group my-3">
                            <input type="password" className="form-control" placeholder="Enter new password"
                                value={newpassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                aria-label="enter new password" />
                        </div>
                    </fieldset>
                    <button type="submit" className="btn btn-outline-dark">Reset Password</button>
                </form>
                <div className="my-3">
                    <Link to="/signin">Sign In</Link> &nbsp; | &nbsp;
                    <Link to="/signup">Sign Up</Link>
                </div>
            </div>
        </Layout>

    )
}
export default ResetPassword