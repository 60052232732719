import React from 'react';
import NavBar
    from './navbar';
import Footer from './footer';



const Layout = ({ children, selected, nocontainer }) => {
    return (
        <>
            <NavBar selected={selected} />
            {nocontainer
                ? <>{children}</>
                : <div className="container min-vh-100">
                    {children}
                </div>
            }
            <Footer />
        </>
    );
}

export default Layout;