import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { doPost, doFetch, doPut, doDelete, clearMyTour, s3upload } from '../../../redux/api/actions';
import { CREATE_TOUR, GET_MYTOUR, EDIT_TOUR, DELETE_TOUR, GET_PRESIGNED_IMAGE_URLS } from '../../../redux/api/constants';
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Layout from "../../layout";
import ImageUploader from "../../imageUploader";

const flavorOptions = ["SMALL", "MEDIUM", "LARGE"]
const adventureOptions = ["COUCH", "IN_BETWEEN", "EXTREME", "ULTRA_EXTREME"]
const durationOptions = {
    SMALL: [1, 2, 3],
    MEDIUM: [4, 5, 6],
    LARGE: [6, 7, 8, 9, 10, 11, 12],
};

const costOptions = ["PER_PERSON", "PER_TOUR"]


function EditTour() {
    const { tourId } = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const tour = useSelector(state => state.API.myTour)
    const [title, setTitle] = useState(tour && tour.title ? tour.title : '')
    const [titleError, setTitleError] = useState('')

    const [tourFlavor, setTourFlavor] = useState(tour && tour.flavor ? tour.flavor : '')
    const [tourFlavorError, setTourFlavorError] = useState('')

    const [adventureSize, setAdventureSize] = useState(tour && tour.adventure_size ? tour.adventure_size : '')
    const [adventureSizeError, setAdventureSizeError] = useState('')

    const [description, setDescription] = useState(tour && tour.description ? tour.description : '')
    const [descriptionError, setDescriptionError] = useState('')

    const [duration, setDuration] = useState(tour && tour.duration_in_hours ? tour.duration_in_hours : '')
    const [durationError, setDurationError] = useState('')

    const [cost, setCost] = useState(tour && tour.cost ? tour.cost : '')
    const [costError, setCostError] = useState('')

    const [costType, setCostType] = useState(tour && tour.cost_type ? tour.cost_type : '')
    const [costTypeError, setTypeError] = useState('')

    const [maxTourSize, setMaxTourSize] = useState(tour && tour.max_tour_size ? tour.max_tour_size : '')
    const [maxTourSizeError, setMaxTourSizeError] = useState('')

    const [imageUrls, setImageUrls] = useState(tour?.image_urls ? tour.image_urls.split(',') : [])

    /* for deleting a tour */
    const [showModal, setShowModal] = useState(false);

    const accessToken = useSelector(state => state.Auth.accessToken);
    const presignedImageUrls = useSelector(state => state.API.presignedImageUrls);

    const [imageFiles, setImageFiles] = useState([])

    useEffect(() => {
        if (tourId) {
            dispatch(doFetch(
                GET_MYTOUR,
                `tourguide/tours/${tourId}`,
                accessToken
            ))
        } else {
            // ensure there are no tours shown if there is no tour id. 
            dispatch(clearMyTour())
        }
    }, [accessToken, tourId, dispatch])

    useEffect(() => {
        if (tour) {
            setTitle(tour.title)
            setDescription(tour.description)
            setCost(tour.cost)
            setTourFlavor(tour.flavor)
            setAdventureSize(tour.adventure_size)
            setDuration(tour.duration_in_hours)
            setCostType(tour.cost_type)
            setMaxTourSize(tour.max_tour_size)
            setImageUrls(tour.image_urls)

        } else {
            setTitle('')
            setDescription('')
            setTourFlavor('')
            setAdventureSize('')
            setDuration('')
            setCost('')
            setCostType('')
            setMaxTourSize('')
            setImageUrls([])
        }
    }, [tour]);

    useEffect(() => {
        setDuration(''); // Reset selected duration when tourFlavor changes
        setDurationError('');
    }, [tourFlavor]);

    useEffect(() => {
        if (presignedImageUrls && presignedImageUrls.length > 0) {
            const processedImageUrls = presignedImageUrls.map(url => url.split('?')[0]);
            setImageUrls(processedImageUrls.join(','));
            dispatch(s3upload(imageFiles, presignedImageUrls));
        }
    }, [presignedImageUrls, dispatch, imageFiles]);

    const deleteTour = () => {
        dispatch(doDelete(
            DELETE_TOUR,
            `tourguide/tours/${tourId}`,
            accessToken,
            navigate
        ))
    }

    const validateAndSubmit = (e) => {
        e.preventDefault();
        setTitleError(null)
        setDescriptionError(null)
        setAdventureSizeError(null)
        setTourFlavorError(null)
        setDurationError(null)
        setCostError(null)
        setTypeError(null)
        setMaxTourSizeError(null)

        var errors = 0
        if (title === "") {
            setTitleError('Title can not be empty.')
            errors++
        }
        if (description === "") {
            setDescriptionError('Description can not be empty.')
            errors++
        }
        if (tourFlavor === "") {
            setTourFlavorError('Please select a tour flavor.')
            errors++
        }
        if (adventureSize === "") {
            setAdventureSizeError('You must select an adventure size.')
            errors++
        }
        if (duration === "") {
            setDurationError('You must select a duration.')
            errors++
        }
        if (cost === "") {
            setCostError('Please specify how much you will charge.')
            errors++
        }
        if (!isNaN(cost) && cost < 1) {
            setCostError('Cost must be a positive number.')
            errors++
        }
        if (costType === "") {
            setTypeError('Cost type should be set.')
            errors++
        }
        if (!isNaN(maxTourSize) && maxTourSize < 1) {
            setMaxTourSizeError('Max Tour Size must be a positive number.')
            errors++
        }

        if (errors > 0) {
            return;
        }



        const params = {
            "title": title,
            "flavor": tourFlavor,
            "adventure_size": adventureSize,
            "duration_in_hours": duration,
            "description": description,
            "cost": cost,
            "cost_type": costType,
            "max_tour_size": maxTourSize,
            "image_urls": (imageUrls && imageUrls.length > 0) ? imageUrls : "", /* if they don't have any image urls */
        }

        console.log("sending params: ", params)

        if (tourId) {
            dispatch(doPut(
                EDIT_TOUR,
                `tourguide/tours/${tourId}`,
                accessToken,
                params,
                navigate
            ))
            return;
        }
        else {
            dispatch(doPost(
                CREATE_TOUR,
                "tourguide/tours",
                accessToken,
                params,
                navigate
            ))
        }
    }

    const imageUploadFunc = (files) => {
        // Get the files and do the following: 
        // 1. Get the presigned Image URLs for them. 
        // 2. Store the files in the fileImages state.
        // When the form is submitted we'll save the files to S3.
        const fileNames = Array.from(files).map((file) => file.name);
        // for each file get a new presigned url 
        // assign that in the state. 
        dispatch(doPost(
            GET_PRESIGNED_IMAGE_URLS,
            "tourguide/presigned-urls",
            accessToken,
            { images: fileNames },
        ))
        setImageFiles(files)
    }

    return (
        <Layout selected="tourguide">
            <div className="container min-vh-100">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                            <Link to="/tourguide#tab2">TourGuide</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{tourId ? <span>Edit Tour</span> : <span>Create Tour</span>}</li>
                    </ol>
                </nav>
                <h1 className="display-6">{tourId ? <span>Edit Tour</span> : <span>Create New Tour</span>}</h1>
                <form onSubmit={validateAndSubmit}>
                    <div className="mb-3">
                        <ImageUploader
                            imageUrls={
                                imageUrls && typeof imageUrls === 'string' ? imageUrls.split(',') : null
                            }
                            imageUploadFunc={imageUploadFunc}
                            multiple={true} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Title</label>
                        <input type="text"
                            className={titleError ? "form-control form-control-lg is-invalid" :
                                "form-control form-control-lg"}
                            id="title"
                            placeholder="What is your tour called?"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            aria-label="enter tour title"
                        />
                        <div className="invalid-feedback">{titleError}</div>
                    </div>
                    <div className="mb-3 row">
                        <div className="col-md-6">
                            <label htmlFor="tourFlavor" className="form-label">Tour Flavor</label>
                            <select
                                id="tourFlavor"
                                className={tourFlavorError ? "form-select form-select-lg form-control is-invalid" :
                                    "form-select form-select-lg form-control"}
                                aria-label="select tour flavor"
                                value={tourFlavor}
                                onChange={(e) => setTourFlavor(e.target.value)}
                            >
                                <option value="">Select a Tour Type</option>
                                {flavorOptions.map((flavor) => (
                                    <option key={flavor} value={flavor}>{flavor}</option>
                                ))}
                            </select>
                            <small id="tourFlavorHelp" className="form-text text-muted">All tours must fit into one category. <Link to="/tourguide/about#tab2">See Why</Link></small>

                            <div className="invalid-feedback">{tourFlavorError}</div>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="duration" className="form-label">Duration</label>
                            <select
                                id="duration"
                                className={durationError ? "form-select form-select-lg form-control is-invalid" :
                                    "form-select form-select-lg form-control"}
                                aria-label="select tour duration"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                            >
                                <option value="">How long is this experience?</option>
                                {durationOptions[tourFlavor]?.map((durationOption) => (
                                    <option key={durationOption} value={durationOption}>{durationOption} hour{durationOption === 1 ? '' : 's'}</option>
                                ))}
                            </select>
                            <small id="durationHelp" className="form-text text-muted">Select Size for different hours.</small>
                            <div className="invalid-feedback">{durationError}</div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="aSize" className="form-label">Adventure Flavor</label>
                        <select
                            id="aSize"
                            className={adventureSizeError ? "form-select form-select-lg form-control is-invalid" :
                                "form-select form-select-lg form-control"}
                            aria-label="select tour flavor"
                            value={adventureSize}
                            onChange={(e) => setAdventureSize(e.target.value)}
                        >
                            <option value="">Select Adventure Level</option>
                            {adventureOptions.map((flavor) => (
                                <option key={flavor} value={flavor}>{flavor}</option>
                            ))}
                        </select>
                        <small id="adventureSizeHelp" className="form-text text-muted">All tours must have an adventure level. <Link to="">See Why</Link></small>

                        <div className="invalid-feedback">{adventureSizeError}</div>

                    </div>
                    <div className="mb-3 row">

                        <div className="col-md-6">
                            <label htmlFor="cost" className="form-label">Cost</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon">$</span>
                                <input type="number"
                                    className={costError ? "form-control form-control-lg is-invalid" :
                                        "form-control form-control-lg"}
                                    id="cost"
                                    placeholder="How much will you charge?"
                                    value={cost}
                                    onChange={(e) => setCost(e.target.value)}
                                    aria-label="enter tour cost"
                                />
                                <div className="invalid-feedback">{costError}</div>
                            </div>
                            <small id="costHelp" className="form-text text-muted">How much do you charge per tour?</small>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="costType" className="form-label">Cost Type</label>
                            <select
                                id="costType"
                                className={adventureSizeError ? "form-select form-select-lg form-control is-invalid" :
                                    "form-select form-select-lg form-control"}
                                aria-label="select tour flavor"
                                value={costType}
                                onChange={(e) => setCostType(e.target.value)}
                            >
                                <option value="">Select Cost Type</option>
                                {costOptions.map((flavor) => (
                                    <option key={flavor} value={flavor}>{flavor}</option>
                                ))}
                            </select>
                            <small id="adventureSizeHelp" className="form-text text-muted">How do you charge for your tour?</small>

                            <div className="invalid-feedback">{costTypeError}</div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="maxTourSize" className="form-label">Max Tour Size</label>
                        <input type="number"
                            className={maxTourSizeError ? "form-control form-control-lg is-invalid" :
                                "form-control form-control-lg"}
                            id="maxTourSize"
                            placeholder="How many people can you take?"
                            value={maxTourSize}
                            onChange={(e) => setMaxTourSize(e.target.value)}
                            aria-label="enter tour max size"
                        />
                        <div className="invalid-feedback">{maxTourSizeError}</div>
                        <small id="maxTourSizeHelp" className="form-text text-muted">This is the maximum number of people you can take on your tour.</small>
                    </div>


                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea id="description"
                            className={descriptionError ? "form-control form-control-lg is-invalid" :
                                "form-control form-control-lg"}
                            placeholder="What is the elevator pitch of this tour?  Why should people take it?"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            rows="3" />
                        <div className="invalid-feedback">{descriptionError}</div>
                    </div>

                    <div className="mb-3">
                        <button type="submit" className="btn btn-lg btn-outline-dark mx-auto">Submit</button>
                        &nbsp;
                        <button type="button" className="btn btn-lg btn-outline-dark mx-auto"
                            onClick={() => navigate("/tourguide#tab2")}>Cancel</button>
                        {tourId &&
                            <>
                                &nbsp;
                                <button type="button" className="btn btn-lg btn-outline-danger mx-auto"
                                    onClick={() => setShowModal(true)}>Delete Tour</button>
                            </>
                        }
                    </div>
                </form>
                <div
                    className={`modal ${showModal ? 'show' : ''}`}
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: showModal ? 'block' : 'none' }}
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => setShowModal(false)}
                                >
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to delete this tour?
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={deleteTour}
                                >
                                    Confirm Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default EditTour;