
import React from 'react';

const ImageUploader = ({ imageUrls, imageUploadFunc, multiple }) => {
    const [images, setImages] = React.useState([]);
    const [imagesError, setImagesError] = React.useState(null);

    const imgPreview = (e) => {
        const fileList = e.target.files;
        setImagesError(null);

        if (fileList.length === 0) {
            setImages([]);
            return
        }
        const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxSizeInBytes = 5e6; // 5MB

        const isValidImages = Array.from(fileList).every((file) => {
            if (!allowedImageTypes.includes(file.type)) {
                setImagesError('Please select an image file (jpeg, png, or gif)');
                return false
            }
            if (file.size > maxSizeInBytes) {
                setImagesError('Please select a smaller file (less than 5MB)');
                return false
            }
            return true;
        });

        if (!isValidImages) {
            return
        }

        imageUploadFunc(fileList)
        const readerPromises = Array.from(fileList).map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.addEventListener('load', (ev) => {
                    resolve(ev.target.result);
                });
                reader.addEventListener('error', reject);
                reader.readAsDataURL(file);
            });
        });

        Promise.all(readerPromises).then((images) => {
            setImages(images);
        });
    }

    return (
        <div className="mb-2">
            <div className="form-group">
                <label htmlFor="images">Images</label>
                <input
                    type="file"
                    className={imagesError ?
                        "form-control form-control-lg is-invalid"
                        : "form-control form-control-lg"}
                    id="images"
                    multiple={!!multiple}
                    onChange={imgPreview} />

                <small id="avatarHelp" className="form-text text-muted">Upload images (5MB Max)</small>
                <div className="invalid-feedback">{imagesError}</div>
                <div className="images">
                    {(images && Array.isArray(images) && images.length > 0 && images[0] !== "") ?
                        images.map((image, index) => (
                            <img
                                id={`preview-${index}`}
                                key={index}
                                src={image}
                                alt="preview"
                                className="img-fluid"
                                style={{ maxWidth: '200px', maxHeight: '200px' }}
                            />
                        ))
                        :
                        imageUrls && imageUrls.length > 0 && imageUrls[0] !== "" && Array.isArray(imageUrls) && imageUrls.map((imageUrl, index) => (
                            <img
                                id={`preview-${index}`}
                                key={index}
                                src={imageUrl}
                                alt="preview"
                                className="img-fluid"
                                style={{ maxWidth: '200px', maxHeight: '200px' }}
                            />

                        ))}

                </div>
            </div>
        </div>
    )
}

export default ImageUploader;