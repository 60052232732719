import React, { useState, useEffect } from 'react';
import validator from 'validator';
import config from '../../config';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getGoogleOauthURL } from '../../redux/auth/actions';
import { useTranslation } from 'react-i18next';
//import AppleSignIn from './apple'
//import AppleLogin from 'react-apple-login'


function SignUpButton({ buttonType, requestFunc, oauthFunc }) {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const googleOAuthURL = useSelector(state => state.Auth.oauth_url);
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [phoneNumberError, setPhoneNumberError] = useState("");
	const communicationMethod = useSelector(state => state.Auth.communicationMethod);



	useEffect(() => {
		const script = document.createElement('script')
		script.src = `https://www.google.com/recaptcha/api.js?render=${config.RECAPTCHA_KEY}`
		document.body.append(script)
		dispatch(getGoogleOauthURL())
	}, [dispatch]);


	const validateAndSubmit = (e) => {
		e.preventDefault();
		setEmailError(null)
		setPasswordError(null)
		setPhoneNumberError(null)

		const emailMethods = ['email', 'both'];
		const smsMethods = ['sms', 'both'];
		if (emailMethods.includes(communicationMethod) && !validator.isEmail(email)) {
			setEmailError("Email is not valid");
			return;
		}

		if (smsMethods.includes(communicationMethod) && !validator.isMobilePhone(phoneNumber)) {
			setPhoneNumberError('Please enter a valid phone number.')
			return;
		}
		if (emailMethods.includes(communicationMethod) && password === "") {
			setPasswordError('Password can not be empty.')
			return;
		}

		requestFunc({ phone: phoneNumber, email: email, password: password, token: '', navigate: navigate });
		/* google captcha
		window.grecaptcha.ready(function () {
			window.grecaptcha.execute(config.RECAPTCHA_KEY, { action: 'submit' }).then(function (token) {
			});
		});
		*/

	}

	return (
		<>
			<div className="mb-3 col-md-4 offset-md-4 text-center">
				<div className="col-md-12">
					<Link to={googleOAuthURL} className="btn btn-outline-dark d-flex justify-content-between align-items-center" >
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google" viewBox="0 0 16 16">
							<path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
						</svg>
						<span className="mx-auto">{t('signin-with-google')}</span>
					</Link>
				</div>
				<hr />
			</div>
			<form onSubmit={validateAndSubmit} id="userForm">
				<fieldset>
					{(communicationMethod === 'sms' || communicationMethod === 'both') &&
						<div className="mb-3 col-md-4 offset-md-4">
							<input className={phoneNumberError ? "form-control form-control-lg is-invalid" :
								"form-control form-control-lg"}
								type="tel"
								placeholder="Phone Number"
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
								aria-label="enter phone number" />
							<div className="invalid-feedback">{phoneNumberError}</div>
						</div>
					}
					{(communicationMethod === 'email' || communicationMethod === 'both' || !communicationMethod) &&
						<div className="mb-3 col-md-4 offset-md-4">
							<input
								className={emailError ? "form-control form-control-lg is-invalid" :
									"form-control form-control-lg"}
								type="text"
								placeholder="email"
								id="email"
								autoComplete='email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								aria-label="enter email" />
							<div className="invalid-feedback">
								{emailError}
							</div>
							<input
								className={passwordError ? "my-2 form-control form-control-lg is-invalid" :
									"form-control form-control-lg my-2"}
								type="password"
								id="password"
								placeholder="password"
								autoComplete='current-password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								aria-label="enter password" />
							<div className="invalid-feedback">
								{passwordError}
							</div>
						</div>
					}
					<div className="mt-3 text-center" >
						<button type="submit" className="btn btn-lg btn-outline-dark mx-auto">{buttonType}</button>
					</div>

				</fieldset>
			</form>

		</>
	)
}

export default SignUpButton;
