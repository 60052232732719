import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from './api';

import {
	CREATE_TOUR,
	CREATE_TOURGUIDE,
	FETCH,
	POST,
	PUT,
	DELETE,
	DELETE_TOUR,
	UPLOAD_FILE_TO_S3
} from './constants';
import { gotResponse, apiError } from './actions';

function* getStuff(action) {
	try {
		const response = yield call(api.get, {
			path: action.path,
			access_token: action.access_token,
		})
		yield put(gotResponse(action.dataType, response))
	} catch (err) {
		yield put(apiError(err));
	}
}

function* postStuff(action) {
	try {
		const response = yield call(api.create, {
			path: action.path,
			access_token: action.access_token,
			data: action.data,
		})
		yield put(gotResponse(action.dataType, response))
		if (action.dataType === CREATE_TOUR) {
			action.navigate("/tourguide#tab2")
		}
		if (action.dataType === CREATE_TOURGUIDE) {
			action.navigate("/tourguide#tab3")
		}

	} catch (err) {
		Object.keys(err).forEach(key => {
			console.log(`${key}:`, err[key]);
		});
		if (err.name === 'TypeError' || err.name === 'NetworkError') {
			yield put(apiError("Error connecting to backend API."));
			return
		} else if ('status 401' in err) {
			yield put(apiError(err));
			action.navigate("/login")
		}
		else if (err?.data?.detail) {
			yield put(apiError(err.response.data.detail));
		}
		else if (err.name === 'Error') {
			yield put(apiError(err.message));
		}
		yield put(apiError(err));
	}
}

function* putStuff(action) {
	try {
		const response = yield call(api.put, {
			path: action.path,
			access_token: action.access_token,
			data: action.data,
		})
		yield put(gotResponse(action.dataType, response))
	} catch (err) {
		if (err?.response?.status === 422 && err?.response?.data?.detail) {
			// Capture details from the 422 response
			const validationErrors = err.response.data.detail;
			yield put(apiError(validationErrors));
		}
		else if (err?.data?.detail) {
			yield put(apiError(err.response.data.detail));
		}
		else if (err.name === 'TypeError' || err.name === 'NetworkError') {
			yield put(apiError("Error connecting to backend API."));
			return
		}
		else if (err.name === 'Error') {
			yield put(apiError(err.message));
		}
		yield put(apiError(err));
	}
}

function* doS3Upload(action) {
	try {
		const response = yield call(api.s3Upload, {
			urls: action.presignedUrls,
			files: action.files,
		})
		yield put(gotResponse(UPLOAD_FILE_TO_S3, response))
	} catch (err) {
		yield put(apiError(err));
	}
}

function* deleteStuff(action) {
	try {
		const response = yield call(api.delete, {
			path: action.path,
			access_token: action.access_token,
		})
		yield put(gotResponse(action.dataType, response))
		if (action.dataType === DELETE_TOUR) {
			action.navigate("/tourguide#tab2")
		}
	} catch (err) {
		yield put(apiError(err));
	}
}

export function* watchAPIActions() {
	yield takeEvery(FETCH, getStuff);
	yield takeEvery(POST, postStuff)
	yield takeEvery(PUT, putStuff)
	yield takeEvery(DELETE, deleteStuff)
	yield takeEvery(UPLOAD_FILE_TO_S3, doS3Upload)
}

function* apiSaga(): any {
	yield all([
		fork(watchAPIActions),
	])
}

export default apiSaga;
