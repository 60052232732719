const dev = {
	STRIPE_PROMISE_KEY: 'pk_test_51KDMtaKbm0gLb8vBTSNLzfrvCuIvr7ygebVA8NEec7VeNpM1fsYsGZO5cLkB3rKq1uXfCu16BZhcZsi1wTmD98mD0089S7EKKB',
	RECAPTCHA_KEY: '6LfH9KYeAAAAAIWZKMqoR_iZsrj8qlyGLKQFSI6Q',
	API_ENDPOINT: "http://localhost",
}

const prod = {
	STRIPE_PROMISE_KEY: 'pk_live_51KDMtaKbm0gLb8vBIZ8b0sGkwwiUELmfZrngtjun9FlsfjJdMyMjC2lbGMgR3lKdEYDdUaJCKooZ7cDRHJIRFvrp00wJSWlXFR',
	RECAPTCHA_KEY: "6Leb5aYeAAAAAEOGnIvYEE3ueWJBjvjn068zON7E",
	API_ENDPOINT: "https://api.torgyd.com",
}

export default process.env.NODE_ENV === "development" ? dev : prod;
