import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Emoji from './emoji';
import { useTranslation } from 'react-i18next';

function Search({ destinations, jumbo }) {
    return (
        <>
            {jumbo ?
                <div className="jumbotron px-5" style={{
                    backgroundImage: 'url("https://s3.us-west-2.amazonaws.com/photos.torgyd.com/images/pexels-quang-nguyen-vinh-2136719.jpg")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    color: 'white',
                    minHeight: '300px',  // Set a minimum height
                    //height: '100vh',    // Set to 100% of the viewport height if needed
                    display: 'flex',    // Use flexbox to center content vertically
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                }} >
                    <SearchForm destinations={destinations} />
                </div >
                :
                <SearchForm destinations={destinations} />
            }
        </>
    )
}

const SearchForm = ({ destinations }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [input, setInput] = useState("");
    const [suggestions, setSuggestions] = useState([])
    const [select, setSelect] = useState("");

    const handleChange = (e) => {
        let value = e.target.value;
        let matches = [];
        if (value.length >= 1) {
            const regex = new RegExp(`${value}`, "gi");
            matches = destinations.filter((item) =>
            (regex.test(item.city) ||
                regex.test(item.country) ||
                regex.test(item.state)
            ));
        }
        setSuggestions(matches);
        setInput(value)
    }

    const setSelectWithChoice = (item) => {
        // replace spaces with dashes in the city: 
        const linkCity = item.city.replace(/\s+/g, '-')
        const linkState = item.state.replace(/\s+/g, '-')
        const linkCountry = item.country.replace(/\s+/g, '-')
        var returnVal = ""

        if (item.state) {
            returnVal = `${linkCity}--${linkState}--${linkCountry}`
            setSelect(returnVal)
        } else {
            returnVal = `${linkCity}--${linkCountry}`
            setSelect(returnVal)
        }
        setInput(item.city + (item.state ? `, ${item.state}` : '') + `, ${item.country}`)
        return returnVal

    }



    const go = (e) => {
        e.preventDefault();
        if (!select && suggestions.length === 1) {
            // If no selection, and there's only one suggestion, auto-select it
            const firstSuggestion = suggestions[0];
            setSelectWithChoice(firstSuggestion)
        }
        if (!select) {
            return
        }
        navigate(`/s/${select}`)
    }
    const goRandom = (e) => {
        e.preventDefault();
        let f = destinations[Math.floor(Math.random() * destinations.length)];
        const destination = setSelectWithChoice(f)
        navigate(`/s/${destination}`)
    }

    const selectValue = (e, item) => {
        e.preventDefault();
        setSelectWithChoice(item)
        setSuggestions([]);
    }
    return (
        <div className="input-group mt-4 container" style={{ maxWidth: '800px' }} >
            <input className='form-control form-control-lg'
                list="suggestions"
                type="text"
                id="search"
                placeholder={t('search-placeholder')}
                value={input}
                onChange={handleChange}
                onKeyDown={(e) => e.key === 'Enter' && go(e)}  // Add this line

            />
            <div className="input-group-append btn-group" role="group">
                <button className="btn btn-primary " type="button" onClick={(e) => go(e)}>{t('search-go')}</button>
                <button className="btn btn-secondary" type="button" onClick={(e) => goRandom(e)}>{t('search-random')}</button>

            </div>
            {
                suggestions.length > 0 ? (
                    <div className="list-group position-absolute mt-5 " style={{ width: '76%' }} >
                        {
                            suggestions.map(item => {
                                return (
                                    <a href="/"
                                        onClick={(e) => selectValue(e, item)}
                                        className='list-group-item'
                                        key={item.id} >
                                        {item.city}{item.state && `, ${item.state}`}, {item.country}
                                        &nbsp;
                                        <Emoji label={item.country} emoji={item.flag} />
                                    </a>
                                )
                            })
                        }
                    </div>
                ) : null
            }
        </div>
    )
}

export default Search;