import React, { useEffect } from "react";
import Layout from "./layout";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { doFetch } from "../redux/api/actions";
import { GET_TOUR } from "../redux/api/constants";
import DisplayTour from "./displayTour";

const Tour = () => {
    const { tourId } = useParams();
    const tour = useSelector(state => state.API.tour);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(doFetch(
            GET_TOUR,
            `tours/${tourId}`,
            null))
    }, [tourId, dispatch])

    return (
        <Layout selected="tour">
            {tour &&
                <DisplayTour tour={tour} />
            }
        </Layout >
    )
}

export default Tour;