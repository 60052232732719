import React from "react";
import { useSelector } from 'react-redux';

function Spinner() {
    const loading = useSelector(state => state.Auth.loading);
    const apiLoading = useSelector(state => state.API.loading);
    return (
        <>
            {
                loading &&
                <div className="text-center">
                    <div className="spinner-border" role="status" />
                </div>
            }
            {
                apiLoading &&
                <div className="text-center">
                    <div className="spinner-border" role="status" />
                </div>
            }

        </>

    );
}

export default Spinner
