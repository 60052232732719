import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_LOCATIONS, CREATE_TOURGUIDE, EDIT_TOURGUIDE, GET_MY_TOURGUIDE_PROFILE, GET_PRESIGNED_IMAGE_URLS } from "../../redux/api/constants";
import { clearPresignedImages, doFetch, doPost, doPut, localEditTourguide, s3upload } from "../../redux/api/actions";
import { useNavigate, Link } from "react-router-dom";
import TourGuideForm from "./tourguideProfileForm";
import Layout from "../layout";

const TourGuideEdit = () => {
    const me = useSelector(state => state.API.me);
    const locations = useSelector(state => state.API.locations);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(state => state.Auth.accessToken);
    const tourguide = useSelector(state => state.API.tourguide);
    const presignedProfileImageUrls = useSelector(state => state.API.presignedImageUrls);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        dispatch(doFetch(GET_LOCATIONS, "locations"))
    }, [dispatch])

    useEffect(() => {
        dispatch(doFetch(
            GET_MY_TOURGUIDE_PROFILE,
            "tourguide/me",
            accessToken,
        ))
    }, [dispatch, accessToken]);

    const submitForm = (params) => {
        /* if the image changed and we have a presigned url, then use that url */
        if (presignedProfileImageUrls && presignedProfileImageUrls.length > 0) {
            const processedImageUrls = presignedProfileImageUrls.map(url => url.split("?")[0])
            params.avatar_url = processedImageUrls[0]
        }

        if (tourguide?.id) {
            dispatch(
                doPut(EDIT_TOURGUIDE,
                    "tourguide",
                    accessToken,
                    { ...tourguide, ...params },
                    navigate))
        } else {
            dispatch(
                doPost(CREATE_TOURGUIDE,
                    "tourguide",
                    accessToken,
                    params,
                    navigate))
        }
        // clear the presigned urls if we use it other places.
        dispatch(clearPresignedImages())
    }

    useEffect(() => {
        if (presignedProfileImageUrls && presignedProfileImageUrls.length > 0 && files.length > 0) {
            dispatch(s3upload(files, presignedProfileImageUrls));
            // after we upload the image, set the profile image url to the new image
            const processedImageUrls = presignedProfileImageUrls.map(url => url.split("?")[0])
            if (tourguide) {
                tourguide.avatar_url = processedImageUrls[0]
                dispatch(localEditTourguide(tourguide))

            } else {
                const newTourguide = { avatar_url: processedImageUrls[0] }
                dispatch(localEditTourguide(newTourguide))
            }
        }
    }, [presignedProfileImageUrls, files, dispatch, tourguide])

    const imageUploadFunc = (passedFiles) => {
        const fileNames = Array.from(passedFiles).map((f) => f.name);
        dispatch(doPost(
            GET_PRESIGNED_IMAGE_URLS,
            "tourguide/presigned-urls",
            accessToken,
            { "images": fileNames }
        ))
        setFiles(passedFiles)
    }

    return (
        <Layout selected="tourguide">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item" aria-current="page">
                        <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                        <Link to="/tourguide#tab3">TourGuide</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Edit Profile</li>
                </ol>
            </nav>

            <h1>Edit Your Guide Profile</h1>
            <TourGuideForm
                me={me}
                formSubmitFunc={submitForm}
                locations={locations}
                tourguide={tourguide}
                imageUploadFunc={imageUploadFunc}
            />
        </Layout>
    )
}

export default TourGuideEdit;