import React, { useEffect } from 'react';
import Routes from './routes';
import "./main.css"
import { useDispatch } from 'react-redux';
import { changeLanguage } from './redux/site/actions';

const getBrowserLanguage = () => {
  const lang = navigator.language || navigator.userLanguage;
  return lang.split('-')[0]; // To get the language code (e.g., 'en' from 'en-US')
};

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const browserLang = getBrowserLanguage();
    dispatch(changeLanguage(browserLang));
  }, [dispatch]);

  return (
    <Routes />
  );
}

export default App;
