import React, { useEffect } from "react";
import Layout from "./layout";
import { useDispatch, useSelector } from "react-redux";
import Search from "./search";
import { GET_LOCATIONS } from '../redux/api/constants';
import { doFetch } from '../redux/api/actions';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


function EatWithTheNatives() {
    const allDestinations = useSelector(state => state.API.locations);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        if (allDestinations.length === 0) {
            dispatch(doFetch(GET_LOCATIONS, "locations"));
        }
    }, [dispatch, allDestinations])

    return (
        <Layout selected="natives" nocontainer="true">
            <div className="container min-vh-100 mt-3 text-center">
                <h1 className="display-1">{t('ewtn-title')}</h1>
                <p className="lead">{t('ewtn-subtitle')}</p>
                <h2 className="display-2">{t('ewtn-rules')}</h2>
                <p className="lead">
                    {t('ewtn-whatnot')}
                </p>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <h3 className="display-6">{t('ewtn-visitor-rules')}</h3>
                        <div className="my-3 lead">
                            {t('ewtn-visitor-rules-subtitle')}
                        </div>
                        <ul className="text-start">
                            <li>{t('ewtn-visitor-rule-1')}</li>
                            <li>{t('ewtn-visitor-rule-2')}</li>
                            <li>{t('ewtn-visitor-rule-3')}</li>
                            <li>{t('ewtn-visitor-rule-4')}</li>
                            <li>{t('ewtn-visitor-rule-5')}</li>
                            <li>{t('ewtn-visitor-rule-6')}</li>
                            <li>{t('ewtn-visitor-rule-7')}</li>
                        </ul>
                        <h3 className="display-6">
                            {t('ewtn-visitor-get-started')}
                        </h3>
                        <p className="lead">
                            {t('ewtn-visitor-howto')}
                        </p>
                        <Search destinations={allDestinations} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <h3 className="display-6">
                            {t('ewtn-local-rules')}
                        </h3>
                        <div className="my-3 lead">
                            {t('ewtn-local-rules-subtitle')}
                        </div>
                        <ul className="text-start">
                            <li>{t('ewtn-local-rule-1')}</li>
                            <li>{t('ewtn-local-rule-2')}</li>
                            <li>{t('ewtn-local-rule-3')}</li>
                            <li>{t('ewtn-local-rule-4')}</li>
                            <li>{t('ewtn-local-rule-5')}</li>
                            <li>{t('ewtn-local-rule-6')}</li>
                            <li>{t('ewtn-local-rule-7')}</li>
                            <li>{t('ewtn-local-rule-8')}</li>
                            <li>{t('ewtn-local-rule-9')}</li>
                        </ul>
                        <h3 className="display-6">
                            {t('ewtn-local-get-started')}
                        </h3>
                        <p className="lead">
                            {t('ewtn-local-howto')}
                        </p>
                        <Link to="/signup" className="btn btn-outline-dark btn-lg">{t('signup')}</Link>

                    </div>

                </div>
                <h2 className="display-2">FAQ</h2>
                <ul className="text-start">
                    <li><b>{t('ewtn-faq-q1')}</b> {t('ewtn-faq-a1')}</li>
                    <li><b>{t('ewtn-faq-q2')}</b> {t('ewtn-faq-a2')}</li>
                    <li><b>{t('ewtn-faq-q3')}</b> {t('ewtn-faq-a3')}</li>
                    <li><b>{t('ewtn-faq-q4')}</b> {t('ewtn-faq-a4')}</li>
                </ul>
            </div>
        </Layout >
    );
}
export default EatWithTheNatives;