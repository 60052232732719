import React, { useState } from 'react';
import validator from 'validator';
import { useDispatch } from 'react-redux';
import { forgotPasswordRequest } from '../../redux/auth/actions';
import { Link } from 'react-router-dom';
import Layout from '../layout';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const dispatch = useDispatch()

    const forgotPasswordMethod = (e) => {
        e.preventDefault();
        setEmailError(null)

        if (!validator.isEmail(email)) {
            setEmailError("Email is not valid");
            return;
        }
        dispatch(forgotPasswordRequest(email));
    }

    return (
        <Layout selected="forgot">
            <div className="container">
                <div className="mb-3 col-md-4 offset-md-4 text-center mt-5">
                    <div className="col-md-12 text-center">
                        <h1>Forgot Password</h1>
                        <form onSubmit={forgotPasswordMethod}>
                            <fieldset>
                                <div className="form-group my-3">
                                    <input type="email" className="form-control"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter email"
                                        aria-label="enter email" />
                                    <div className="invalid-feedback">
                                        {emailError}
                                    </div>
                                </div>
                                <button type="submit"
                                    className="btn btn-outline-dark ">
                                    Reset Password
                                </button>
                            </fieldset>
                        </form>
                    </div>
                </div>
                <div className="mt-3 text-center">
                    Already Have an Account? <Link to="/signin">Sign In</Link>
                </div>
            </div>
        </Layout>
    )
}

export default ForgotPassword